import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { REQUEST } from 'src/app/tokens/tokens';

@Injectable({ providedIn: 'root' })
export class UniversalDeviceDetectorService extends DeviceDetectorService {
  deviceInfo: DeviceInfo;
  constructor(
    @Inject(PLATFORM_ID) platformId: any,
    @Optional() @Inject(REQUEST) private request: Request
  ) {
    super(platformId);
    if (isPlatformBrowser(platformId)) {
      this.deviceInfo = super.getDeviceInfo();
    }
    if (isPlatformServer(platformId) && this.request) {
      super.setDeviceInfo((this.request.headers['user-agent'] as string) || '');
    }
  }
}
