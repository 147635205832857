import { Component, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit, Input, OnChanges, OnInit } from '@angular/core';
import { Product } from 'src/app/home/interfaces/shop.interface';

@Component({
  selector: 'app-product-ticket-upcoming',
  templateUrl: './product-ticket-upcoming.component.html',
  styleUrls: ['../product-ticket.component.scss'],
})
export class ProductTicketUpcomingComponent implements OnChanges, AfterViewInit, OnInit {
  @Input('ticket') ticket: Product;
  expand = false;
  @ViewChild('productDescription', { static: true }) productDescription: ElementRef;
  isDescriptionCollapsed = false;
  currentWindowWidth;
  showTooltip = false;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (this.ticket.description) {
      if (this.productDescription) {
        this.isDescriptionCollapsed = this.checkDescrCollapsed(this.productDescription.nativeElement);
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.ticket.description) {
      if (this.productDescription) {
        this.isDescriptionCollapsed = this.checkDescrCollapsed(this.productDescription.nativeElement);
      }
    }
    this.cdRef.detectChanges();
  }

  checkDescrCollapsed(element) {
    if (element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth) {
      return true;
    } else {
      return false;
    }
  }
}
