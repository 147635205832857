<form class="standard-form mt-3" [formGroup]="detailsForm">
  <div class="row mt-2">
    <div class="col-sm-12 mt-1 input-wrapper">
      <label for="email">{{ 'details.details_manually.e-mail' | transloco }}*</label>
      <mat-form-field appearance="outline" class="primary">
        <input
          matInput
          id="email"
          placeholder="{{ 'details.details_manually.e-mail' | transloco }}"
          formControlName="email"
          [(ngModel)]="emailInput"
          required
        />
        <mat-error *ngIf="getFormControl('email').invalid">
          <ng-container *ngIf="getFormControl('email').errors.required">
            {{ 'details.details_manually.field_is_required' | transloco }}
          </ng-container>
          <ng-container *ngIf="getFormControl('email').errors.email">
            {{ 'details.details_manually.not_a_valid_email' | transloco }}
          </ng-container>
          <ng-container *ngIf="getFormControl('email').errors.not_deliverable">
            {{ 'form.error.email_not_deliverable' | transloco }}
          </ng-container>
        </mat-error>
      </mat-form-field>
      <div class="errors-container" *ngIf="userExistsWarning">
        <div class="already-exists-container">
          <p class="recognized-email">
            {{ 'form.error.' + 'we_recognized_your_email' | transloco }}
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-6 mt-1 input-wrapper">
      <label for="first_name">{{ 'details.details_manually.first_name' | transloco }}*</label>
      <mat-form-field appearance="outline" class="primary">
        <input
          matInput
          id="first_name"
          placeholder="{{ 'details.details_manually.first_name' | transloco }}"
          formControlName="first_name"
          required
        />
        <mat-error *ngIf="getFormControl('first_name').invalid">
          <ng-container *ngIf="getFormControl('first_name').errors.required">
            {{ 'details.details_manually.field_is_required' | transloco }}
          </ng-container>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-6 mt-1 input-wrapper">
      <label for="last_name">{{ 'details.details_manually.last_name' | transloco }}*</label>
      <mat-form-field appearance="outline" class="primary">
        <input matInput placeholder="{{ 'details.details_manually.last_name' | transloco }}" formControlName="last_name" required />
        <mat-error *ngIf="getFormControl('last_name').invalid">
          <ng-container *ngIf="getFormControl('last_name').errors.required">
            {{ 'details.details_manually.field_is_required' | transloco }}
          </ng-container>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</form>
