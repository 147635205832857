<div class="loading-container" *ngIf="loading">
  <div class="spinner-container">
    <mat-spinner class="primary" [diameter]="40"></mat-spinner>
  </div>
</div>
<div class="auth-form" [ngClass]="{ loading: loading }">
  <div class="col-12">
    <form class="auth-form" [formGroup]="form">
      <p class="code-info">{{ 'global.code' | transloco }}</p>
      <div class="code-input-container">
        <code-input [codeLength]="6" (codeCompleted)="onCodeCompleted($event)" [initialFocusField]="0"> </code-input>
        <div *ngIf="customErrors.rateLimited" class="error-info">{{ 'form.error.rate_limited' | transloco }}</div>
        <div *ngIf="customErrors.incorrectCode" class="error-info">{{ 'form.error.incorrect_code' | transloco }}</div>
      </div>
    </form>

    <p tabindex="0" class="text-color-primary resend-code-text cta-btn" (keydown)="resendCode()" (click)="resendCode()">
      {{ 'global.resend_code' | transloco }}
    </p>

    <div class="custom-error" *ngIf="customErrors.invalidCaptcha || customErrors.emptyCaptcha">
      <ng-container *ngIf="customErrors.emptyCaptcha">
        {{ 'form.error.required' | transloco }}
      </ng-container>
      <ng-container *ngIf="customErrors.invalidCaptcha">
        {{ 'form.error.invalid' | transloco }}
      </ng-container>
    </div>

    <button class="btn btn-primary within-dialog d-block w-100 btn-submit mt-3" (click)="executeCaptcha()">
      {{ 'global.next' | transloco }}
    </button>
  </div>
</div>
