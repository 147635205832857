<div class="loading-container" *ngIf="loading">
  <div class="spinner-container">
    <mat-spinner class="primary" [diameter]="40"></mat-spinner>
  </div>
</div>
<div class="row auth-form" [ngClass]="{ loading: loading }">
  <div *ngIf="form" class="col-12">
    <form class="auth-form" [formGroup]="form">
      <app-auth-form-auth-id-types
        [authType]="authType"
        [form]="form"
        [customErrors]="customErrors"
        (registrationOptionChange)="onRegistrationOptionChange($event)"
        (countryPrefixChange)="onCountryPrefixChange($event)"
        (loadingChange)="validationLoading = $event"
      >
      </app-auth-form-auth-id-types>
    </form>

    <div class="agreements-container">
      <p>
        <span>{{ 'global.agreements' | transloco }} </span>
        <span
          tabindex="0"
          class="text-color-primary cta-btn"
          [routerLink]="['/terms-of-use']"
          (keydown)="closeDialog()"
          (click)="closeDialog()"
          >{{ 'global.appic_user_agreement' | transloco }},
        </span>
        <span
          tabindex="1"
          class="text-color-primary cta-btn"
          [routerLink]="['/privacy-policy']"
          (keydown)="closeDialog()"
          (click)="closeDialog()"
          >{{ 'global.privacy_policy' | transloco }}</span
        >
      </p>
    </div>
    <button class="btn btn-primary within-dialog d-block w-100 btn-submit mt-3" (keydown)="changeStep()" (click)="changeStep()">
      {{ 'global.create_account' | transloco }}
    </button>
    <a
      class="btn btn-facebook wide-btn"
      (click)="setFacebookRedirect(true)"
      href="facebook-login-redirect/shop/{{ shopService.shop.url_extension }}/shopping-cart/{{
        shopService.shoppingCart ? shopService.shoppingCart.uuid : ''
      }}?{{ shopService.source ? '&source=' + shopService.source : '' }}{{
        shopService.campaign ? '&campaign=' + shopService.campaign : ''
      }}"
    >
      <svg-icon src="{{ assetsDir }}assets/icons/icon-fb-2.svg" />
      <span class="ion ion-logo-facebook"></span>
      {{ 'global.continue_with_facebook' | transloco }}
      <mat-spinner *ngIf="shopService.facebookRedirect" class="fb-spinner" [diameter]="32"></mat-spinner>
    </a>
  </div>
</div>
