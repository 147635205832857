import { Settings } from './user.interface';

export interface AuthenticationContext {
  shopping_cart_uuid?: string;
  order_uuid?: string;
}

export interface LoginRequest {
  email: string;
  password: string;
  context: AuthenticationContext;
}

export interface RegisterRequest {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  language: string;
  country: string;
  context: AuthenticationContext;
  captcha_token: string;
  gender?: number;
}

export type RegisterData = PartialBy<Omit<RegisterRequest, 'captcha_token' | 'context'>, 'password' | 'language'>;

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export interface FacebookLoginRequest {
  data: UpdateForm;
  context: AuthenticationContext;
}

export interface UpdateForm {
  email?: string;
  gender?: string;
  first_name?: string;
  last_name?: string;
  city?: string;
  country?: string;
  birth_date?: string;
}

export interface NullUpdateForm {
  gender?: string;
  first_name?: string;
  last_name?: string;
  city?: string;
  country?: string;
  birth_date?: string;
}

export interface ResetPasswordRequest {
  email: string;
  captcha_token: string;
}

export interface ProfileSettingsRequest {
  first_name: string;
  last_name: string;
  birth_date: string;
  gender: number;
  city: string;
  country: string;
  lat: number;
  lon: number;
}

export interface EmailValidation {
  email: string;
}

export interface EmailValidationResponse {
  did_you_mean: string;
  is_deliverable: boolean;
}
export interface PasswordCondition {
  contains_regex_pattern: string;
  name: string;
  not_contains_regex_pattern: string;
  type: string;
}

export interface ChangePasswordRequest {
  password1: string;
  password2: string;
  token: string;
}

export interface ValidatePhoneRequest {
  phone_number: string;
  phone_country_code: string;
  captcha_token: string;
}

export interface ValidateEmailRequest {
  email: string;
  captcha_token: string;
}

export interface ConfirmationCodeRequest {
  code: string;
  captcha_token: string;
}

export interface ValidatePhoneResponse {
  code: string;
  is_valid: boolean;
  reason: string;
}

export interface ValidateEmailResponse {
  code: string;
  is_valid: boolean;
  reason: string;
}

export enum RegistrationOption {
  Email = 'email',
  Phone = 'phone_number',
}
export enum AuthTypes {
  Login = 'login',
  Register = 'register',
}
export interface Token {
  refresh: string;
  access: string;
}

export interface UpdateRequest {
  fb_id: string;
  fb_access_token: string;
  data: {
    email: string;
    first_name: string;
    last_name: string;
    gender: string;
    birth_date: string;
    city: string;
    country: string;
  };
}

export enum AuthDialogType {
  Login = 'login',
  Register = 'register',
  RegisterDetails = 'registerDetails',
  UpdateDetails = 'updateDetails',
  SignUpWith = 'signUpWith',
  ConfirmationCode = 'confirmationCode',
  SetPassword = 'setPassword',
  UserProfileDetails = 'userProfileDetails',
}
