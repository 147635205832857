<div class="loading-container" *ngIf="loading">
  <div class="spinner-container">
    <mat-spinner class="primary" [diameter]="40"></mat-spinner>
  </div>
</div>
<div class="row auth-form form-user-details" [ngClass]="{ loading: loading }">
  <div class="col-12">
    <form class="auth-form" [formGroup]="form">
      <div class="form-field-container">
        <div class="form-field-label">
          {{ 'form.field.first_name' | transloco }}
        </div>
        <mat-form-field appearance="outline" class="form-field" floatLabel="auto">
          <input matInput placeholder="{{ 'form.field.first_name' | transloco }}" formControlName="first_name" required />
          <mat-error *ngIf="getFormControl('first_name').invalid">
            <ng-container *ngIf="getFormControl('first_name').errors.required">
              {{ 'form.error.required' | transloco }}
            </ng-container>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-field-container">
        <div class="form-field-label">
          {{ 'form.field.last_name' | transloco }}
        </div>
        <mat-form-field appearance="outline" class="form-field" floatLabel="auto">
          <input matInput placeholder="{{ 'form.field.last_name' | transloco }}" formControlName="last_name" required />
          <mat-error *ngIf="getFormControl('last_name').invalid">
            <ng-container *ngIf="getFormControl('last_name').errors.required">
              {{ 'form.error.required' | transloco }}
            </ng-container>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-field-container">
        <!--  [checked]="getFormControl('use_nickname')?.value" -->
        <mat-checkbox formControlName="use_nickname">{{ 'form.field.use_nickname' | transloco }}</mat-checkbox>
      </div>

      <div class="form-field-container" *ngIf="getFormControl('use_nickname')?.value">
        <div class="form-field-label">
          {{ 'form.field.nickname' | transloco }}
        </div>
        <mat-form-field appearance="outline" class="form-field" floatLabel="auto">
          <input matInput placeholder="{{ 'form.field.nickname' | transloco }}" formControlName="nick_name" />
        </mat-form-field>
      </div>

      <div class="form-field-container">
        <div class="form-field-label">
          {{ 'form.field.gender' | transloco }}
        </div>
        <div class="row">
          <div class="col-6 gender-btn-container">
            <button
              class="btn btn-gender btn-primary within-dialog d-block w-100 male"
              [ngClass]="{ active: gender === 1 }"
              (click)="setGender(1); $event.preventDefault()"
            >
              <svg-icon class="btn-icon" src="{{ assetsDir }}assets/icons/icon-male.svg" />
              {{ 'form.field.male' | transloco }}
            </button>
          </div>
          <div class="col-6 gender-btn-container">
            <button
              class="btn btn-gender btn-primary within-dialog d-block w-100 female"
              [ngClass]="{ active: gender === 2 }"
              (click)="setGender(2); $event.preventDefault()"
            >
              <svg-icon class="btn-icon" src="{{ assetsDir }}assets/icons/icon-female.svg" />
              {{ 'form.field.female' | transloco }}
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12 custom-error p-0" *ngIf="customErrors.emptyGender">
            {{ 'form.error.required' | transloco }}
          </div>
        </div>
      </div>

      <div class="form-field-container">
        <div class="form-field-label">
          {{ 'form.field.date_of_birth' | transloco }}
        </div>
        <div class="row">
          <div class="col-4 col-md-3 date-input-container">
            <mat-form-field appearance="outline" class="form-field date-input" floatLabel="auto" (click)="myDatepicker.open()">
              <input type="number" matInput placeholder="DD" formControlName="birth_date_dd" />
              <input
                type="date"
                matInput
                [max]="maxDate"
                [matDatepicker]="myDatepicker"
                (dateChange)="dateChange($event.value); myDatepicker.close()"
                [ngStyle]="{ display: 'none' }"
              />
              <mat-datepicker #myDatepicker [panelClass]="'datepicker-calendar'" [yPosition]="'above'"></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-4 col-md-3 date-input-container">
            <mat-form-field appearance="outline" class="form-field date-input" floatLabel="auto" (click)="myDatepicker.open()">
              <input type="number" matInput placeholder="MM" formControlName="birth_date_mm" />
            </mat-form-field>
          </div>
          <div class="col-4 col-md-3 date-input-container">
            <mat-form-field
              appearance="outline"
              class="form-field date-input year-input-container"
              floatLabel="auto"
              (click)="myDatepicker.open()"
            >
              <input type="number" matInput placeholder="YYYY" formControlName="birth_date_yyyy" />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12 custom-error p-0" *ngIf="customErrors.emptyDate">
            {{ 'form.error.required' | transloco }}
          </div>
          <div class="col-12 custom-error p-0" *ngIf="customErrors.invalidDate">
            {{ 'form.error.invalid_date' | transloco }}
          </div>
        </div>
      </div>

      <div class="form-field-container">
        <div class="form-field-label">
          {{ 'form.field.city' | transloco }}
        </div>
        <app-city-selector
          [control]="getFormControl('city')"
          (cityChange)="setCountry($event)"
          (inputBlur)="checkCity()"
        ></app-city-selector>
      </div>
      <div class="form-field-container">
        <div class="form-field-label">{{ 'details.details_manually.country' | transloco }}</div>
        <div class="row">
          <mat-form-field appearance="outline" class="primary">
            <mat-select
              class="no-arrow"
              placeholder="{{ 'details.details_manually.country' | transloco }}"
              formControlName="country"
              required
            >
              <mat-option *ngFor="let country of countries?.primary" [value]="country.code">{{ country.name }}</mat-option>
              <mat-option disabled></mat-option>
              <mat-option *ngFor="let country of countries?.other" [value]="country.code">{{ country.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="getFormControl('country').invalid">
              <ng-container *ngIf="getFormControl('country').errors.required">
                {{ 'details.details_manually.field_is_required' | transloco }}
              </ng-container>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="form-field-container pb-3">
        <div class="custom-error" *ngIf="customErrors.invalidCaptcha || customErrors.emptyCaptcha">
          <ng-container *ngIf="customErrors.emptyCaptcha">
            {{ 'form.error.required' | transloco }}
          </ng-container>
          <ng-container *ngIf="customErrors.invalidCaptcha">
            {{ 'form.error.invalid' | transloco }}
          </ng-container>
        </div>
      </div>

      <button class="btn btn-primary within-dialog d-block w-100 btn-submit mb-5 mt-3" (click)="executeCaptcha()">
        {{ 'global.next' | transloco }}
      </button>
    </form>
  </div>
</div>
