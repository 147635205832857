import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class VisibilityChangeService {
  private _docHidden = new BehaviorSubject<boolean>(false);
  docHidden$ = this._docHidden.asObservable();
  timeStamp;
  constructor(@Inject(PLATFORM_ID) private platform: Object) {
    if (isPlatformBrowser(this.platform)) {
      document.addEventListener('visibilitychange', () => {
        this._docHidden.next(document.hidden);
      });
    }
  }
}
