import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { map, Subscription, take, timer } from 'rxjs';
import { WinProduct } from 'src/app/home/interfaces/shopping-cart.interface';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-win-ticket',
  templateUrl: './win-ticket.component.html',
  styleUrls: ['./win-ticket.component.scss'],
})
export class WinTicketComponent implements OnInit, OnDestroy {
  @Input() winProduct: WinProduct;
  timerSub: Subscription;
  assetsDir = '/';
  expireTimer: number;

  winTicketReservedFor: any;
  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  ngOnInit() {
    if (environment.production) {
      this.assetsDir = environment.deploy_url;
    }
    const now = new Date();
    const exp = new Date(this.winProduct.reserved_until.replace(/-/g, '/'));
    const seconds = Math.floor((+exp - +now) / 1000);

    if (exp > now) {
      this.startTimer(seconds);
    }
  }

  public startTimer(seconds) {
    if (isPlatformBrowser(this.platformId)) {
      if (this.timerSub) {
        this.timerSub.unsubscribe();
      }
      this.timerSub = timer(0, 1000)
        .pipe(
          take(seconds + 1),
          map((value) => seconds - value)
        )
        .subscribe({
          next: (value) => {
            this.expireTimer = value;
          },
        });
    }
  }

  ngOnDestroy(): void {
    if (this.timerSub) {
      this.timerSub.unsubscribe();
    }
  }
}
