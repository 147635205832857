<div *ngIf="control" class="city-selector-container">
  <mat-form-field appearance="outline" class="form-field" floatLabel="auto">
    <input
      type="text"
      matInput
      (blur)="emitInputBlur()"
      placeholder="{{ 'form.field.city' | transloco }}"
      [formControl]="control"
      autocomplete="off"
      required
      [matAutocomplete]="autoCity"
    />
    <mat-autocomplete class="autocomplete-panel" position="below" #autoCity="matAutocomplete">
      <mat-option *ngFor="let city of cityList" [value]="city.city" (click)="onCityChange(city)">
        {{ city.city }}, <span class="text-color-grey">{{ city.province }}</span
        >, {{ city.country | uppercase }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="control.invalid">
      <ng-container *ngIf="control.errors.required">
        {{ 'form.error.required' | transloco }}
      </ng-container>
      <ng-container *ngIf="control.errors.select_city">
        {{ 'details.new_account_appic.select_from_list' | transloco }}
      </ng-container>
    </mat-error>
  </mat-form-field>
</div>
