<div class="dropdown">
  <div class="dropdown-src">
    <div #content (appClickOutside)="handleClickOutside()" [exception]="secondEl" class="content-wrapper">
      <ng-content></ng-content>
    </div>
    <ul #secondEl [ngClass]="{ block: isListVisible }">
      <li tabindex="0" (keydown)="clickedEl(dropEl.value)" (click)="clickedEl(dropEl.value)" *ngFor="let dropEl of dropData">
        <span class="title">{{ dropEl.title }}</span>
      </li>
    </ul>
  </div>
</div>
