import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-lib-nested-dropdown',
  templateUrl: './nested-dropdown.component.html',
  styleUrls: ['./nested-dropdown.component.scss'],
})
export class NestedDropdownComponent implements OnInit, AfterViewInit {
  @ViewChild('content') childComp: ElementRef;
  @Input() dropData: DropDownData[] = [];
  @Input() selectedValue: boolean;
  @Output() elementClicked = new EventEmitter();
  @Output() droplistToggle = new EventEmitter<boolean>();
  isListVisible = false;
  constructor() {}

  ngAfterViewInit(): void {
    this.childComp.nativeElement.addEventListener('click', () => {
      this.isListVisible = !this.isListVisible;
      this.droplistToggle.emit(this.isListVisible);
    });
  }

  ngOnInit(): void {}

  contentClicked() {}

  handleClickOutside() {
    this.isListVisible = false;
    this.droplistToggle.emit(false);
  }

  clickedEl(value: any) {
    this.elementClicked.emit(value);
  }
}

export interface DropDownData<T = string> {
  title: string;
  value: T;
}
