import { Component, OnInit, Input, Output, EventEmitter, NgZone } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { CanDeactivate } from 'src/app/home/can-deactivate/can-deactivate';
import { AuthAPIService } from 'src/app/home/services/auth-api.service';
import { RegisterRequest } from 'src/app/home/interfaces/auth.interface';
import { User } from 'src/app/home/interfaces/user.interface';
import { TranslocoService } from '@ngneat/transloco';
import { AppComponent } from 'src/app/app.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ShopService } from 'src/app/home/services/shop.service';
import { ShopApiService } from 'src/app/home/services/shop-api.service';
import { UserSessionService } from 'src/app/home/services/user-session.service';
import { environment } from 'src/environments/environment';
import { matchOtherValidator } from 'src/app/utils/matchOtherValidator';
import * as Sentry from '@sentry/browser';

@Component({
  selector: 'app-appic-update-password-form',
  templateUrl: './appic-update-password-form.component.html',
  styleUrls: ['../forms.component.scss'],
})
export class AppicUpdatePasswordFormComponent extends CanDeactivate implements OnInit {
  appicUpdatePasswordForm: UntypedFormGroup;
  @Input('color') eventColor;
  @Output() updateVariantEmiter = new EventEmitter();
  recaptchaKey;
  customErrors = {
    emptyCaptcha: false,
    invalidCaptcha: false,
  };

  constructor(
    private fB: UntypedFormBuilder,
    private authApiService: AuthAPIService,
    private userSessionService: UserSessionService,
    private translateService: TranslocoService,
    private appComponent: AppComponent,
    private router: Router,
    private route: ActivatedRoute,
    protected shopService: ShopService,
    protected shopApiService: ShopApiService,
    private zone: NgZone
  ) {
    super(shopApiService, shopService);
  }

  ngOnInit() {
    this.appicUpdatePasswordForm = this.fB.group({
      password: this.fB.control('', [Validators.required, Validators.minLength(6)]),
      repeatPassword: this.fB.control('', [Validators.required, matchOtherValidator('password')]),
      captcha_token: this.fB.control('', [Validators.required]),
    });

    this.recaptchaKey = environment.recaptcha;
  }

  getFormControl(name) {
    return this.appicUpdatePasswordForm.get(name);
  }

  onSubmit() {
    const user: User = this.userSessionService.user;
    const registerRequest: RegisterRequest = {
      email: user.email,
      password: this.getFormControl('password').value,
      first_name: user.first_name,
      last_name: user.last_name,
      country: user.country,
      language: this.translateService.getActiveLang(),
      context: {
        order_uuid: this.userSessionService.order_uuid,
      },
      captcha_token: this.getFormControl('captcha_token').value,
    };

    if (!registerRequest.captcha_token) {
      this.customErrors.emptyCaptcha = true;
    } else {
      this.authApiService.register(registerRequest).subscribe(
        () => {
          this.updateVariantEmiter.emit();
        },
        (error) => {
          if (error.error.captcha_token) {
            this.customErrors.invalidCaptcha = true;
          } else {
            Sentry.captureException(error.error || error, {
              tags: {
                feature: 'update-password',
              },
            });
            this.appComponent.openDialog(
              'application_errors.an_error_has_occured',
              (callback) => {
                if (callback) {
                  this.router.navigate(['../tickets'], {
                    relativeTo: this.route,
                  });
                }
              },
              error.status
            );
          }
        }
      );
    }
  }

  getForm(): UntypedFormGroup {
    return this.appicUpdatePasswordForm;
  }

  canCloseTab(): boolean {
    return !this.getForm().dirty;
  }

  onCaptchaChange() {
    this.customErrors.emptyCaptcha = false;
    this.customErrors.invalidCaptcha = false;
  }
}
