import { Component, OnInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthDialogType } from 'src/app/home/interfaces/auth.interface';
import { AuthDialogComponent } from '../auth-dialog.component';
import { DialogService } from 'src/app/home/services/dialog.service';
import { ForgotPasswordDialogComponent } from '../forgot-password-dialog/forgot-password-dialog.component';
import { AuthAPIService } from 'src/app/home/services/auth-api.service';
import { environment } from 'src/environments/environment';
import { ShopService } from 'src/app/home/services/shop.service';
import { UserSessionService } from 'src/app/home/services/user-session.service';
import { ShopApiService } from 'src/app/home/services/shop-api.service';
import { isUserVerified } from 'src/app/utils/isUserVerified';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { isUserMissingData } from 'src/app/utils/isUserMissingData';
import { catchError, map, switchMap, throwError } from 'rxjs';
import * as Sentry from '@sentry/browser';

enum LoginSteps {
  LogInWith = 'logInWith',
  Password = 'password',
}
@Component({
  selector: 'app-auth-form-login',
  templateUrl: './auth-form-login.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./auth-form-login.component.scss', '../auth-dialog.component.scss'],
})
export class AuthFormLoginComponent implements OnInit {
  @Output() forgotRedirect: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() loginSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() changeAuthDialogType = new EventEmitter<{ type: AuthDialogType; existingAccount?: boolean }>();
  form = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    password: new UntypedFormControl('', [Validators.required]),
  });
  hide = true;
  capsOn;
  loading = false;
  currentStep = LoginSteps.LogInWith;

  constructor(
    private authApiService: AuthAPIService,
    /*     private fbLoginService: FbLoginService, */
    private dialogRef: MatDialogRef<AuthDialogComponent>,
    private dialog: MatDialog,
    private dialogService: DialogService,
    public shopService: ShopService,
    private userSessionService: UserSessionService,
    private shopApiService: ShopApiService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {}
  assetsDir = '/';
  ngOnInit(): void {
    if (environment.production) {
      this.assetsDir = environment.deploy_url;
    }
  }

  getFormControl(name: string) {
    return this.form.get(name);
  }

  continue() {
    if (this.currentStep === LoginSteps.LogInWith) {
      if (this.isContactUsedAndValid()) {
        this.currentStep = LoginSteps.Password;
      }
    } else {
      this.onSubmit();
    }
  }

  isContactUsedAndValid(): 'email' | 'phone_number' | false {
    const emailUsedAndValid = this.form.get('email') && this.form.get('email').valid;
    if (emailUsedAndValid) return 'email';
    const phoneUsedAndValid = this.form.get('phone_number') && this.form.get('phone_number').valid;
    if (phoneUsedAndValid) return 'phone_number';
    return false;
  }

  onSubmit() {
    if (this.form.valid) {
      this.loading = true;
      if (this.form.get('phone_number')?.value) {
        const countryPrefix = this.form.get('phone_country_code')?.value;
        this.form.get('phone_number').setValue(this.form.get('phone_number').value?.substring(countryPrefix?.length));
      }
      const context = this.userSessionService.order_uuid
        ? { order_uuid: this.userSessionService.order_uuid }
        : this.shopService.shoppingCart
          ? { shopping_cart_uuid: this.shopService.shoppingCart.uuid }
          : null;
      this.authApiService
        .login({ ...this.form.getRawValue() })
        .pipe(
          catchError((error) => {
            if (error.status === 400) {
              this.getFormControl('password').setErrors({
                incorrect_email_password: true,
              });
            }
            this.loading = false;
            return throwError(() => {});
          }),
          switchMap((user) => {
            return this.shopApiService.bindOrder(context).pipe(map(() => user));
          })
        )
        .subscribe(
          (user) => {
            this.userSessionService.user = user;

            if (isUserVerified(user)) {
              /* because of the way user session is build, bindOrder in ng serve mode with return "Authentication credentials were not provided." */
              /* consider running serve:ssr */
              const missingData = isUserMissingData(user);
              if (missingData) {
                this.changeAuthDialogType.emit({ type: AuthDialogType.UserProfileDetails, existingAccount: true });
              } else {
                this.loginSuccess.emit(true);
                this.dialog.closeAll();
              }
            } else {
              this.sendCodeAndMoveToConfirm();
            }
          },
          (error) => {
            Sentry.captureException(error.error || error, {
              tags: {
                feature: 'auth-dialog',
              },
            });
            this.loading = false;
          }
        );
    }
  }

  sendCodeAndMoveToConfirm() {
    const request =
      this.isContactUsedAndValid() === 'email' ? this.authApiService.resendEmailCode() : this.authApiService.resendPhoneCode();
    request.subscribe(
      () => {
        this.changeAuthDialogType.emit({ type: AuthDialogType.ConfirmationCode, existingAccount: true });
      },
      (error) => {
        Sentry.captureException(error.error || error, {
          tags: {
            feature: 'auth-dialog',
          },
        });
      }
    );
  }

  setFacebookRedirect(param) {
    this.shopService.facebookRedirect = param;
  }
  onForgotClick() {
    this.forgotRedirect.emit(true);
    this.dialogService.openDialog(ForgotPasswordDialogComponent, { width: '600px' });
  }
}
