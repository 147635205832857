import { Component, Input, OnInit } from '@angular/core';
import { FutureProduct } from 'src/app/home/interfaces/shop.interface';

@Component({
  selector: 'app-product-ticket-future',
  templateUrl: './product-ticket-future.component.html',
  styleUrls: ['../product-ticket.component.scss', './product-ticket-future.component.scss'],
})
export class ProductTicketFutureComponent implements OnInit {
  @Input('ticket') ticket: FutureProduct;
  @Input('eventName') eventName: string;
  @Input('busy') busy: boolean;
  @Input('color') eventColor: string;
  ticketCounter = false;

  constructor() {}

  ngOnInit(): void {}
}
