<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<div class="sub-payment-method-container" [ngClass]="{ active: active }" (keydown)="setSelectedVariant()" (click)="setSelectedVariant()">
  <svg-icon class="check" *ngIf="active" [src]="assetsDir + 'assets/icons/icon-check.svg'"></svg-icon>

  <div class="sub-payment-thumbnail-container">
    <img class="sub-payment-thumbnail" alt="Payment thumbnail" [src]="paymentVariant.logo_image" />
  </div>
  <div class="align-self-center sub-container-text">
    <span class="sub-payment-name">{{ paymentVariant.display_name }}</span>
  </div>
</div>
