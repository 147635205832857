import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { PaymentMethod, SelectedPayment, PaymentVariant } from '../../interfaces/shopping-cart.interface';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss'],
})
export class PaymentMethodComponent implements OnInit {
  @Input('color') eventColor: string;
  @Input('paymentMethod') paymentMethod: PaymentMethod;
  @Input('active') active: boolean;
  hover: boolean;
  selectedVariant: string;
  selectedVariantMobile: PaymentVariant;

  @Output() setSelectedEmiter = new EventEmitter<SelectedPayment>();

  constructor() {}

  ngOnInit(): void {}

  setSelected() {
    const selectedPayment: SelectedPayment = {
      payment_name: this.paymentMethod.name,
      payment_variant: this.selectedVariant ? this.selectedVariant : null,
    };
    this.setSelectedEmiter.emit(selectedPayment);
  }

  getSelectedVariantImage(name: string): string {
    if (this.paymentMethod.variants.length) {
      const url = this.paymentMethod.variants.find((obj) => obj.name === name).logo_image;
      return url;
    }
  }
}
