import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, FormGroup, FormControl } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { switchMap } from 'rxjs';

import { ChangePasswordRequest } from 'src/app/home/interfaces/auth.interface';
import { AuthAPIService } from 'src/app/home/services/auth-api.service';
import { matchOtherValidator } from 'src/app/utils/matchOtherValidator';
import { environment } from 'src/environments/environment';
import * as Sentry from '@sentry/browser';

@Component({
  selector: 'app-auth-form-reset',
  templateUrl: './auth-form-reset.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AuthFormResetComponent implements OnInit {
  @Input() token;
  showEmailForm;
  showPasswordForm;
  successMessage;
  loading = false;
  hide = true;
  capsOn;
  passwordValidators = [];
  passwordConditions = [];
  passwordSubmitEnabled = false;

  formEmail = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    phone_number: new UntypedFormControl('', [Validators.required]),
  });

  formPassword = new FormGroup({
    password1: new FormControl(''),
    password2: new FormControl('', [Validators.required, matchOtherValidator('password1')]),
  });
  assetsDir = '/';
  constructor(
    private authApiService: AuthAPIService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {}

  ngOnInit(): void {
    if (environment.production) {
      this.assetsDir = environment.deploy_url;
    }
    this.authApiService.getPasswordConditions().subscribe(
      (response: any) => {
        for (const validator of response.conditions) {
          this.passwordValidators.push(Validators.pattern(validator.contains_regex_pattern));
        }
        this.passwordConditions = response.conditions;
        this.formPassword.controls['password1'].setValidators(this.passwordValidators);
        if (this.token) {
          this.showPasswordForm = true;
        } else {
          this.showEmailForm = true;
        }
      },
      (error) => {
        Sentry.captureException(error.error || error, {
          tags: {
            feature: 'register',
          },
        });
      }
    );
  }

  getFormControl(name: string) {
    if (this.formEmail.get(name)) {
      return this.formEmail.get(name);
    } else {
      return this.formPassword.get(name);
    }
  }

  onEmailSubmit() {
    if (this.formEmail.valid) {
      this.loading = true;
      const email = this.formEmail.getRawValue().email;
      this.recaptchaV3Service
        .execute('reset_password')
        .pipe(
          switchMap((captcha_token) => {
            return this.authApiService.resetPassword({ ...email, captcha_token });
          })
        )
        .subscribe(
          () => {
            this.successMessage = 'success_reset';
            this.showEmailForm = false;
            this.loading = false;
          },
          (error) => {
            Sentry.captureException(error.error || error, {
              tags: {
                feature: 'resetPassword',
              },
            });
            this.loading = false;
          }
        );
    }
  }

  onPasswordSubmit() {
    this.formPassword.markAllAsTouched();
    this.formPassword.updateValueAndValidity();

    if (this.formPassword.valid) {
      this.loading = true;
      const payload = {
        ...this.formPassword.value,
        token: this.token,
      } as ChangePasswordRequest;
      this.authApiService.changePassword(payload).subscribe(
        () => {
          this.successMessage = 'success_change';
          this.showPasswordForm = false;
          this.loading = false;
        },
        (error) => {
          Sentry.captureException(error.error || error, {
            tags: {
              module: 'auth-dialog',
            },
          });
          this.successMessage = 'wrong_link';
          this.showPasswordForm = false;
          this.loading = false;
        }
      );
    }
  }

  checkCondition(input) {
    for (const condition of this.passwordConditions) {
      const regex = new RegExp(condition.contains_regex_pattern);
      const element = document.querySelector(`#${condition.type}`) as HTMLElement;
      if (regex.test(input.value)) {
        element.style.color = '#128700';
      } else {
        element.style.color = '#000000';
      }
    }
  }

  isConditionMet(condition) {
    const regex = new RegExp(condition.contains_regex_pattern);
    return regex.test(this.formPassword.get('password')?.value);
  }
}
