import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthDialogType, RegisterData } from '../../interfaces/auth.interface';
import { matchOtherValidator } from 'src/app/utils/matchOtherValidator';
import { CaptchaService } from '../../services/captcha.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/auth.service';
import { UserSessionService } from '../../services/user-session.service';
import { getLastActivatedRouteInChain } from 'src/app/utils/getLastActivatedRouteInChain';
import { ActivatedRoute, Router } from '@angular/router';
import { ShopService } from '../../services/shop.service';
import { isUserMissingData } from 'src/app/utils/isUserMissingData';

@Component({
  selector: 'app-auth-dialog',
  templateUrl: './auth-dialog.component.html',
  styleUrls: ['./auth-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AuthDialogComponent implements OnInit, OnDestroy {
  authDialogType: AuthDialogType;
  header;
  assetsDir = '/';
  AuthDialogType = AuthDialogType;
  countryPrefix: string;
  existingAccountBeforeBuy = false;

  signUpWithForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
    ]),
  });

  passwordForm = new FormGroup({
    captcha_token: new UntypedFormControl(''),
    password: new UntypedFormControl('', [Validators.required]),
    confirm_password: new FormControl('', [Validators.required, matchOtherValidator('password')]),
  });

  registrationForm = new UntypedFormGroup({
    first_name: new UntypedFormControl('', [Validators.required]),
    last_name: new UntypedFormControl('', [Validators.required]),
    use_nickname: new UntypedFormControl(false),
    nickname: new UntypedFormControl(''),
    gender: new UntypedFormControl('', [Validators.required]),
    birth_date: new UntypedFormControl('', [Validators.required]),
    birth_date_dd: new UntypedFormControl(''),
    birth_date_mm: new UntypedFormControl(''),
    birth_date_yyyy: new UntypedFormControl(''),
    city: new UntypedFormControl('', [Validators.required]),
    country: new UntypedFormControl('', [Validators.required]),
    language: new UntypedFormControl('', [Validators.required]),
    captcha_token: new UntypedFormControl('', [Validators.required]),
  });

  constructor(
    public dialogRef: MatDialogRef<AuthDialogComponent, AuthDialogComponentClose>,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: AuthDialogComponentData,
    private captchaService: CaptchaService,
    private userSessionService: UserSessionService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private shopService: ShopService
  ) {}

  ngOnInit(): void {
    if (environment.production) {
      this.assetsDir = environment.deploy_url;
    }
    this.dialogRef.afterClosed().subscribe((resp) => {
      if (
        (this.authDialogType === AuthDialogType.ConfirmationCode || this.authDialogType === AuthDialogType.UserProfileDetails) &&
        resp.cancelled
      ) {
        this.authService.logOut();
      }
    });
    this.setDialogType(this.data?.type || AuthDialogType.Login);
    this.captchaService.setCaptchaVisibility('visible');
  }

  loginSuccess() {
    this.redirectToPayment();
    this.dialogRef.close({ cancelled: false, lastStep: this.authDialogType });
  }

  detailsUpdatedSuccess() {
    if (this.data?.redirectToPayment) {
      this.redirectToPayment();
    }
    this.dialogRef.close({ cancelled: false, lastStep: this.authDialogType });
  }

  onCountryPrefixChange(prefix) {
    this.countryPrefix = prefix;
  }

  setDialogType(authDialogType: AuthDialogType) {
    this.authDialogType = authDialogType;
    this.dialogRef.disableClose = true;
    switch (this.authDialogType as AuthDialogType) {
      case AuthDialogType.Login: {
        this.header = 'sign_in';
        break;
      }
      case AuthDialogType.UpdateDetails: {
        /* this case is not used, some ui is not updated */
        this.header = 'update_account';
        break;
      }
      case AuthDialogType.SignUpWith: {
        this.header = 'sign_up_with';
        break;
      }
      case AuthDialogType.ConfirmationCode: {
        this.header = 'confirmation_code';
        break;
      }
      case AuthDialogType.SetPassword: {
        this.header = 'set_password';
        break;
      }
      case AuthDialogType.UserProfileDetails: {
        this.dialogRef.disableClose = true;
        this.header = 'create_account';
        break;
      }
    }
  }

  closeDialogBeforeRedirectingToForgotPassword() {
    /* this emits afterClose to parent of this dialog and opens new forgotPasswordDialog from auth-form-login
    parent loses reference, should be refactored*/
    this.dialogRef.close({ cancelled: false, lastStep: this.authDialogType });
  }

  closeDialog() {
    this.dialogRef.close({ cancelled: true, lastStep: this.authDialogType });
  }

  changeType(type: AuthDialogType, existingAccount = false) {
    if (existingAccount) {
      this.data.registerData = this.userSessionService.user;
      this.data.redirectToPayment = true;
    }
    this.existingAccountBeforeBuy = existingAccount;
    this.setDialogType(type);
  }

  onSignUpWithStepChange() {
    this.setDialogType(AuthDialogType.SetPassword);
  }

  onSetPasswordStepChange() {
    this.setDialogType(AuthDialogType.ConfirmationCode);
  }

  onConfirmationCodeStepChange() {
    if (!this.existingAccountBeforeBuy) {
      this.setDialogType(AuthDialogType.UserProfileDetails);
      return;
    }
    const missingData = isUserMissingData(this.data.registerData);
    if (!missingData) {
      this.redirectToPayment();
      this.dialogRef.close({ cancelled: false, lastStep: this.authDialogType });
      return;
    }

    this.setDialogType(AuthDialogType.UserProfileDetails);
  }

  private redirectToPayment() {
    const lastChildRoute = getLastActivatedRouteInChain(this.activatedRoute);
    this.router.navigate(['../payment'], {
      relativeTo: lastChildRoute,
      queryParams: this.shopService.getQueryParams(),
    });
  }

  ngOnDestroy(): void {
    this.captchaService.setCaptchaVisibility('hidden');
  }
}

export interface AuthDialogComponentData {
  redirectToPayment?: boolean;
  registerData?: RegisterData;
  type?: AuthDialogType;
  detailsClose?: boolean;
}
export interface AuthDialogComponentClose {
  cancelled?: boolean;
  lastStep?: AuthDialogType;
}
