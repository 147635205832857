import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { OrderPanelOptions } from '../interfaces/component.interface';

@Injectable({ providedIn: 'root' })
export class OrderSummaryService {
  private _options = new BehaviorSubject<OrderPanelOptions>(null);
  options$ = this._options.asObservable();
  get options() {
    return this._options.value;
  }
  set options(options: OrderPanelOptions) {
    this._options.next(options);
  }

  private _nextStepClicked = new Subject();
  nextStepClicked$ = this._nextStepClicked.asObservable();
  set nextStepClicked(value: boolean) {
    this._nextStepClicked.next(value);
  }

  private _submitOrder = new Subject();
  submitOrder$ = this._submitOrder.asObservable();
  set submitOrder(submitOrder: boolean) {
    this._submitOrder.next(submitOrder);
  }
  constructor() {}
}
