<div class="navigation" *ngIf="shop">
  <div class="d-flex">
    <div class="align-self-center event-thumbnail" *ngIf="shop.ui.logo_image">
      <img class="event-thumbnail-img" [src]="shop.ui.logo_image" alt="event-thumbnail" />
    </div>
    <div class="align-self-center event-info">
      <div class="event-name">
        <h1 class="event-name-link">{{ shop.event.name }}</h1>
      </div>
      <ul class="list-inline event-details" *ngIf="shop.event">
        <li class="list-inline-item">
          <svg-icon src="{{ assetsDir }}assets/icons/location-icon.svg" [svgStyle]="{ 'marginRight.px': 5 }" />
          <span class="event-detail street">{{ shop.event.street }}</span>
          <span class="event-detail city">{{ shop.event.city }}</span>
          <span class="event-detail country">{{ shop.event.country }}</span>
          <span class="event-detail country-short">{{ shop.event.country_short }}</span>
        </li>
        <br />
        <li class="list-inline-item">
          <svg-icon src="{{ assetsDir }}assets/icons/calendar-icon.svg" [svgStyle]="{ 'marginRight.px': 5 }" />
          @if (datesAreOnSameDay(shop.event.start, shop.event.end)) {
            <span class="event-detail date"
              >{{ convertDate(shop.event.start) | date: 'dd/MM/yyyy HH:mm' }} - {{ convertDate(shop.event.end) | date: 'HH:mm' }}</span
            >
          } @else {
            <span class="event-detail date"
              >{{ convertDate(shop.event.start) | date: 'dd/MM/yyyy HH:mm' }} -
              {{ convertDate(shop.event.end) | date: 'dd/MM/yyyy HH:mm' }}</span
            >
          }
        </li>
      </ul>
    </div>
  </div>
</div>
