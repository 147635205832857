<ng-container *ngIf="{ value: shoppingCart$ | async } as sc">
  <div class="row m-0 summary-counter desktop" *ngIf="sc.value?.summary.products.length">
    <div class="col-12 p-0" [ngClass]="{ 'counter-warning': warningColor }">
      <span class="counter-text mb-1">
        {{ 'order.shopping_cart_summary.ticketsReserved' | transloco }}
      </span>
      <span class="counter" *ngIf="expireTimer">
        {{ expireTimer | timeHourMinSec }}
      </span>
      <span class="counter" *ngIf="!expireTimer">00:00</span>
    </div>
  </div>

  <div class="summary-container">
    <div class="summary-top col-12">
      <div class="blank-summary mb-1" *ngIf="!sc.value?.summary.products.length">
        <img src="{{ assetsDir }}assets/icons/tickets-icon.svg" alt="tickets" />
        <p>
          {{ 'order.shopping_cart_summary.your_cart_is_empty' | transloco }}<br />
          {{ 'order.shopping_cart_summary.selectTickets' | transloco }}
        </p>
      </div>
      <div tabindex="1" class="row mobile" (keydown)="expand = !expand" (click)="expand = !expand">
        <div class="col-12 p-0 text-center expand-summary align-items-end">
          <div class="expand-line"></div>
        </div>
      </div>
      <div class="expander" [ngClass]="{ hide: !expand }">
        <div class="row summary-items m-0 mobile-scroll" *ngIf="sc.value?.summary.products.length">
          <h1 class="summary-header mb-2 p-0">
            {{ 'order.shopping_cart_summary.header' | transloco }}
          </h1>

          <div class="col-12 p-0 product-list-container">
            <div class="product-list">
              <div class="row order-item product" *ngFor="let product of sc.value.summary.products">
                <div class="col p-0 order-item-name text-left">{{ product.quantity }}x {{ product.title }}</div>
                <div class="col-auto p-0 order-item-price text-right">
                  {{ product.total | currency: product.currency }}
                </div>
              </div>
            </div>
            <ng-container *ngIf="sc.value?.summary.total_service_fee > 0">
              <div class="row order-item">
                <div class="col p-0 order-item-name text-left">
                  {{ 'order.shopping_cart_summary.service_fee' | transloco }}
                </div>
                <div class="col-auto p-0 order-item-price service-fee text-right">
                  {{ sc.value.summary.total_service_fee | currency: sc.value.summary.currency }}
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="sc.value?.summary.total_transaction_fee > 0">
              <div class="row order-item">
                <div class="col p-0 order-item-name text-left">
                  {{ 'order.shopping_cart_summary.transaction_fee' | transloco }}
                </div>
                <div class="col-auto p-0 order-item-price transaction-fee text-right">
                  {{ sc.value.summary.total_transaction_fee | currency: sc.value.summary.currency }}
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="row m-0 summary-counter mobile" *ngIf="sc.value?.summary.products.length">
          <span class="counter-text p-0">
            {{ 'order.shopping_cart_summary.ticketsReservedMobile' | transloco }}
          </span>
          <span class="counter p-0" *ngIf="expireTimer">
            {{ expireTimer | timeHourMinSec }}
          </span>
          <span class="counter p-0" *ngIf="!expireTimer">00:00</span>
        </div>
      </div>
      <div class="col col-lg-12 text-lg-right text-left total-price p-lg-0 mb-lg-4 mt-2 desktop" *ngIf="sc.value?.summary.products.length">
        <span>{{ 'order.shopping_cart_summary.total' | transloco }}</span>
        <span class="price">{{ sc.value.summary.total_amount | currency: sc.value.summary.currency }}</span>
      </div>
    </div>
    <div class="row summary-bottom align-items-center justify-content-between m-0">
      <p class="col-6 mobile total-mobile-price p-0 m-0">
        <span class="text">{{ 'order.shopping_cart_summary.total' | transloco }}</span>
        <span class="price" *ngIf="sc.value">{{
          sc.value ? (sc.value.summary.total_amount || 0 | currency: sc.value.summary.currency || 'EUR') : 0
        }}</span>
      </p>
      <ng-container *ngIf="options$ | async as options">
        <div class="col-auto col-lg-12 text-right p-0" *ngIf="options.nextButton">
          <button
            class="btn btn-summary"
            [disabled]="sc.value?.summary.products.length ? options.nextButtonDisabled : true"
            (click)="goNextStep(options)"
          >
            {{ 'order.shopping_cart_summary.continue' | transloco }}
          </button>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="mobile-underline mobile"></div>
</ng-container>
