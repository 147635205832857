import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { AuthAPIService } from '../../services/auth-api.service';
import { ShopApiService } from '../../services/shop-api.service';
import { ShopService } from '../../services/shop.service';
import { UserSessionService } from '../../services/user-session.service';
import { User } from '../../interfaces/user.interface';
import { matchOtherValidator, regexValidatorFunction } from 'src/app/utils/matchOtherValidator';
import { AuthDialogType, PasswordCondition, RegistrationOption } from '../../interfaces/auth.interface';
import { AppComponent } from 'src/app/app.component';
import { AuthService } from '../../services/auth.service';
import { AuthDialogComponentData } from '../auth-dialog/auth-dialog.component';
import { environment } from 'src/environments/environment';
import * as Sentry from '@sentry/browser';

@Component({
  selector: 'app-create-account-from-password',
  templateUrl: './create-account-from-password.component.html',
  styleUrls: ['./create-account-from-password.component.scss'],
})
export class CreateAccountFromPasswordComponent implements OnInit {
  @Output() registered = new EventEmitter();
  repeatVisible = false;
  accountFormPassword: FormGroup;
  hide = true;
  customErrors = {
    emptyCaptcha: false,
    invalidCaptcha: false,
  };
  passwordValidators = [];
  passwordConditions = [];
  creatingAppicAccount = false;
  assetsDir = '/';
  constructor(
    private fB: FormBuilder,
    private authAPIService: AuthAPIService,
    protected shopService: ShopService,
    protected shopApiService: ShopApiService,
    private translateService: TranslocoService,
    private userSessionService: UserSessionService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private authApiService: AuthAPIService,
    private appComponent: AppComponent,
    private authService: AuthService
  ) {}

  ngOnInit() {
    if (environment.production) {
      this.assetsDir = environment.deploy_url;
    }

    this.accountFormPassword = this.fB.group({
      password: this.fB.control('', [Validators.required]),
      repeatPassword: this.fB.control('', [Validators.required, matchOtherValidator('password')]),
    });
    this.authApiService.getPasswordConditions().subscribe(
      (response: { conditions: PasswordCondition[] }) => {
        for (const condition of response.conditions) {
          this.passwordValidators.push(regexValidatorFunction(condition));
        }
        this.passwordConditions = response.conditions;
        this.accountFormPassword.get('password').addValidators(this.passwordValidators);
      },
      (error) => {
        Sentry.captureException(error.error || error, {
          tags: {
            module: 'extra',
            feature: 'create-account-from-password',
          },
        });
      }
    );
  }

  setRepeatVisible() {
    if (this.repeatVisible) return;
    setTimeout(() => {
      this.repeatVisible = true;
    }, 500);
  }

  onSubmit(token) {
    const user: User = this.userSessionService.user;
    this.creatingAppicAccount = true;
    if (!user) {
      this.userSessionService.getProfile().subscribe(() => {
        this.onSubmit(token);
      });
      return;
    }
    if (user && this.accountFormPassword.valid) {
      this.authAPIService
        .register({
          email: user.email,
          password: this.accountFormPassword.get('password').value,
          first_name: user.first_name,
          last_name: user.last_name,
          country: user.country,
          language: this.translateService.getActiveLang(),
          context: {
            order_uuid: this.userSessionService.order_uuid,
          },
          captcha_token: token,
        })
        .subscribe(
          (response) => {
            this.userSessionService.user = response;

            const data: AuthDialogComponentData = {
              registerData: {
                email: user.email,
                password: this.accountFormPassword.get('password').value,
                country: user.country,
                first_name: user.first_name,
                last_name: user.last_name,
                language: this.translateService.getActiveLang(),
              },
              type: AuthDialogType.ConfirmationCode,
              detailsClose: true,
            };
            this.registered.emit();
            sessionStorage.setItem('activeRegistrationOption', RegistrationOption.Email);
            this.authService.openAuthDialog({ data });
          },

          (error) => {
            if (error.error.captcha_token) {
              this.customErrors.invalidCaptcha = true;
            } else {
              Sentry.captureException(error.error || error, {
                tags: {
                  module: 'extra',
                  feature: 'create-account-from-password',
                },
              });
            }
            this.appComponent.openDialog('application_errors.an_error_has_occured');
          }
        );
    } else {
      this.accountFormPassword.updateValueAndValidity();
    }
  }

  getErrorKeys(controlName: string): string[] {
    const control = this.accountFormPassword.controls[controlName];
    return Object.keys(control.errors);
  }

  executeCaptcha() {
    const observer = {
      next: (token) => {
        this.customErrors.emptyCaptcha = false;
        this.customErrors.invalidCaptcha = false;
        this.onSubmit(token);
      },
    };

    this.recaptchaV3Service.execute('register').subscribe(observer);
  }
  onCaptchaChange() {
    this.customErrors.emptyCaptcha = false;
    this.customErrors.invalidCaptcha = false;
  }
  getLanguage(): 'nl' | 'en' {
    const currentLang = this.translateService.getActiveLang();
    return currentLang === 'nl' ? currentLang : 'en';
  }

  isConditionMet(condition) {
    const regex = new RegExp(condition.contains_regex_pattern);
    return regex.test(this.accountFormPassword.get('password')?.value);
  }
}
