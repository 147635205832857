import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { PaymentVariant } from '../../interfaces/shopping-cart.interface';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment-variant',
  templateUrl: './payment-variant.component.html',
  styleUrls: ['./payment-variant.component.scss'],
})
export class PaymentVariantComponent implements OnInit {
  @Input('paymentMethod') paymentVariant: PaymentVariant;
  @Input('active') active: boolean;
  borderColor: string;
  hover: boolean;
  assetsDir = '';
  @Output() setSelectedVariantEmiter = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {
    if (environment.production) {
      this.assetsDir = environment.deploy_url;
    }
  }

  setSelectedVariant() {
    this.setSelectedVariantEmiter.emit(this.paymentVariant.name);
  }
}
