import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-radio-btn-check',
  templateUrl: './radio-btn-check.component.html',
  styleUrls: ['./radio-btn-check.component.scss'],
})
export class RadioBtnCheckComponent implements OnInit, ControlValueAccessor {
  @Input() value: boolean;
  @Input() isDisabled = false;
  @Input() labelText: string | undefined;

  model: any;
  private onChange: (value: any) => void;
  onTouched = () => {};
  constructor() {}

  ngOnInit() {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: any) {
    this.model = value;
  }

  select() {
    if (this.model !== this.value) {
      this.model = this.value;
      this.onChange(this.model);
    }
  }
}
