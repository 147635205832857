import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  ChangePasswordRequest,
  ConfirmationCodeRequest,
  FacebookLoginRequest,
  LoginRequest,
  PasswordCondition,
  ProfileSettingsRequest,
  RegisterRequest,
  ResetPasswordRequest,
  ValidateEmailRequest,
  ValidateEmailResponse,
  ValidatePhoneRequest,
  ValidatePhoneResponse,
} from '../interfaces/auth.interface';
import { CookieOptions } from 'express';
import { UpdateSettings, User } from '../interfaces/user.interface';

export const TOKEN_EXPIRATION = 5; // In minutes
export const REFRESH_TOKEN_EXPIRATION = 60 * 24 * 100; // In minutes (24h * 100)
export const COOKIE_OPTIONS: CookieOptions = {
  sameSite: 'strict',
  secure: true,
};
@Injectable()
export class AuthAPIService {
  constructor(
    private http: HttpClient
    /*   private cookieService: CookieService */
  ) {}

  profile = () => this.http.get<User>('api/me');

  deleteProfile = (shopUuid) => this.http.delete(`api/me/${shopUuid}`);

  register = (registerRequest: RegisterRequest) => this.http.post<User>(`api/v2/users/registration`, registerRequest);

  login = (loginRequest: LoginRequest) => this.http.post<User>(`api/v2/users/login`, loginRequest);

  transferSession = (transfer_token: string) =>
    this.http.get<User>(`rest-api/v1/login-tokens-shop/${transfer_token}`, { params: { transfer_token } });

  updateSettings = (profileSettingsRequest: ProfileSettingsRequest) =>
    this.http.post<UpdateSettings>('api/auth/profile/settings', profileSettingsRequest);

  facebookLogin = (facebookLoginRequest: FacebookLoginRequest) => this.http.post(`api/auth/facebook/login`, facebookLoginRequest);

  setSessionLanguage = (language: string) => this.http.post(`api/lang`, { lang: language });

  getSessionLanguage = () => this.http.get(`api/lang`);

  resetPassword = (resetPasswordRequest: ResetPasswordRequest) => this.http.post('rest-api/v1/users/reset_password', resetPasswordRequest);

  getPasswordConditions = () => this.http.get<{ conditions: PasswordCondition[] }>('rest-api/v1/password/conditions');

  changePassword = (changePasswordRequest: ChangePasswordRequest) => this.http.post(`api/v1/users/change_password`, changePasswordRequest);

  validatePhone = (validatePhoneRequest: ValidatePhoneRequest) =>
    this.http.post<ValidatePhoneResponse>(`api/v2/users/validate_phone`, validatePhoneRequest);

  validateEmail = (validateEmailRequest: ValidateEmailRequest) =>
    this.http.post<ValidateEmailResponse>(`api/v2/users/validate_email`, validateEmailRequest);

  resendPhoneCode = () => this.http.post(`api/v2/users/login_methods/phone/resend_validation`, {});

  resendEmailCode = () => this.http.post(`api/v2/users/login_methods/email/resend_validation`, {});

  validatePhoneConfirmationCode = (confirmationCodeRequest: ConfirmationCodeRequest) =>
    this.http.post(`rest-api/v2/users/login_methods/phone/validate`, confirmationCodeRequest);

  validateEmailConfirmationCode = (confirmationCodeRequest: ConfirmationCodeRequest) =>
    this.http.post(`rest-api/v2/users/login_methods/email/validate`, confirmationCodeRequest);
}
