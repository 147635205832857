<div class="auth-panel fadeIn" *ngIf="authDialogType">
  <div class="auth-panel-header text-center">
    <div class="row m-0">
      <div *ngIf="!(authDialogType === AuthDialogType.UserProfileDetails && data.detailsClose)" class="close-icon-container">
        <svg-icon class="icon-close" src="{{ assetsDir }}assets/icons/icon-close.svg" (keydown)="closeDialog()" (click)="closeDialog()" />
      </div>
      <div class="col-12 header" *ngIf="header">
        {{ 'global.' + header | transloco }}
      </div>
    </div>
  </div>
  <div class="auth-panel-body">
    <div class="row m-0 justify-content-center">
      <div class="col-12 col-md-10">
        <app-auth-form-login
          (forgotRedirect)="closeDialogBeforeRedirectingToForgotPassword()"
          (loginSuccess)="loginSuccess()"
          (changeAuthDialogType)="changeType($event.type, $event.existingAccount)"
          *ngIf="authDialogType === AuthDialogType.Login"
        ></app-auth-form-login>
        <app-auth-form-sign-up-with
          *ngIf="authDialogType === AuthDialogType.SignUpWith"
          [form]="signUpWithForm"
          (stepChange)="onSignUpWithStepChange()"
          (countryPrefixChange)="onCountryPrefixChange($event)"
        ></app-auth-form-sign-up-with>
        <app-auth-form-update-details
          (detailsUpdatedSuccess)="detailsUpdatedSuccess()"
          [data]="data"
          *ngIf="authDialogType === AuthDialogType.UpdateDetails"
        ></app-auth-form-update-details>
        <app-auth-form-confirmation-code
          *ngIf="authDialogType === AuthDialogType.ConfirmationCode"
          (stepChange)="onConfirmationCodeStepChange()"
        ></app-auth-form-confirmation-code>
        <app-auth-form-user-details
          *ngIf="authDialogType === AuthDialogType.UserProfileDetails"
          [data]="data.registerData"
          (detailsUpdatedSuccess)="detailsUpdatedSuccess()"
        ></app-auth-form-user-details>
        <app-auth-form-password
          [signUpWithForm]="signUpWithForm"
          [passwordForm]="passwordForm"
          *ngIf="authDialogType === AuthDialogType.SetPassword"
          (stepChange)="onSetPasswordStepChange()"
        ></app-auth-form-password>
      </div>
    </div>
  </div>
  <div class="auth-panel-bottom" *ngIf="authDialogType !== 'userProfileDetails'">
    <div class="row m-0 justify-content-center">
      <div class="col-12 col-md-10">
        <p
          *ngIf="authDialogType === AuthDialogType.Login"
          tabindex="0"
          (keydown)="changeType(AuthDialogType.SignUpWith)"
          (click)="changeType(AuthDialogType.SignUpWith)"
          class="auth-panel-bottom-info"
        >
          {{ 'global.dont_have_an_account' | transloco }}
          <span class="text-color-primary cta-btn">{{ 'global.create_account' | transloco }}</span>
        </p>
        <p
          *ngIf="authDialogType === AuthDialogType.SignUpWith"
          tabindex="1"
          (keydown)="changeType(AuthDialogType.Login)"
          (click)="changeType(AuthDialogType.Login)"
          class="auth-panel-bottom-info"
        >
          {{ 'global.already_got_an_account' | transloco }}
          <span class="text-color-primary cta-btn">{{ 'global.sign_in' | transloco }}</span>
        </p>
      </div>
    </div>
  </div>
</div>
