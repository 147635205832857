<div class="info-container">
  <h2>{{ winProduct.title }}</h2>
  <p>
    <span class="">Free</span>
    &nbsp;
    <span class="fee">(+ {{ winProduct.service_fee | currency: winProduct.currency }} {{ 'product.fee' | transloco }})</span>
  </p>
</div>
<div class="btn-container">
  <span>
    <svg-icon src="{{ assetsDir }}assets/icons/icon-gray-minus.svg" />
  </span>
  &nbsp; &nbsp;
  <span>1</span>
  &nbsp; &nbsp;

  <span>
    <svg-icon src="{{ assetsDir }}assets/icons/icon-gray-plus.svg" />
  </span>
</div>
<div class="time-badge" *ngIf="expireTimer">
  <p>Ticket available for {{ expireTimer | timeHourMinSec }}</p>
</div>
