import { Component, Output, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subject } from 'rxjs';
import { AuthAPIService } from 'src/app/home/services/auth-api.service';
import { ConfirmationCodeRequest, RegistrationOption } from 'src/app/home/interfaces/auth.interface';
import { UserSessionService } from 'src/app/home/services/user-session.service';
import { AuthService } from 'src/app/home/services/auth.service';
import * as Sentry from '@sentry/browser';

@Component({
  selector: 'app-auth-form-confirmation-code',
  templateUrl: './auth-form-confirmation-code.component.html',
  styleUrls: ['./auth-form-confirmation-code.component.scss', '../auth-dialog.component.scss'],
})
export class AuthFormConfirmationCodeComponent implements OnDestroy, OnInit {
  @Output() stepChange: EventEmitter<number> = new EventEmitter<number>();

  destroy$ = new Subject<boolean>();

  form = new UntypedFormGroup({
    captcha_token: new UntypedFormControl('', [Validators.required]),
  });

  loading = false;

  customErrors = {
    emptyCaptcha: false,
    invalidCaptcha: false,
    rateLimited: false,
    incorrectCode: false,
  };
  recaptchaKey;
  code: number;

  constructor(
    private authApiService: AuthAPIService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private userSessionService: UserSessionService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.recaptchaKey = environment.recaptcha;
  }

  getFormControl(name: string) {
    return this.form.get(name);
  }

  executeCaptcha() {
    if (this.code && ('' + this.code).length === 6) {
      const observer = {
        next: (token) => {
          this.onCaptchaChange();
          this.getFormControl('captcha_token').setValue(token);
          this.onSubmit();
        },
      };

      this.recaptchaV3Service.execute('confirm_code').pipe(takeUntil(this.destroy$)).subscribe(observer);
    }
  }

  onSubmit() {
    if (!this.form.get('captcha_token').value) {
      this.customErrors.emptyCaptcha = true;
    }

    if ((this.form.valid || !this.form.errors) && !this.customErrors.emptyCaptcha) {
      this.sendCode({ ...this.form.getRawValue(), code: this.code });
    }
  }

  sendCode(registerRequest: ConfirmationCodeRequest) {
    this.loading = true;
    this.customErrors.incorrectCode = false;
    const activeRegistrationOption = sessionStorage.getItem('activeRegistrationOption');
    const emailUsedInPayment: string | undefined = this.userSessionService.user?.email;
    const request =
      emailUsedInPayment || activeRegistrationOption === RegistrationOption.Email
        ? this.authApiService.validateEmailConfirmationCode(registerRequest)
        : this.authApiService.validatePhoneConfirmationCode(registerRequest);

    request.subscribe(
      (response: any) => {
        this.stepChange.emit(response);
        this.loading = false;
      },
      (error) => {
        /*  const err = error?.error?.fields_errors; */
        Sentry.captureException(error.error || error, {
          tags: {
            module: 'extra',
            feature: 'auth-dialog',
          },
        });
        if (error?.error?.captcha_token) {
          this.customErrors.invalidCaptcha = true;
        }
        if (error?.error?.error?.code === 'incorrect_code') {
          this.customErrors.incorrectCode = true;
        }
        this.loading = false;
      }
    );
  }

  resendCode() {
    this.loading = true;
    this.customErrors.rateLimited = false;
    const activeRegistrationOption = sessionStorage.getItem('activeRegistrationOption');
    const emailUsedInPayment: string | undefined = this.userSessionService.user?.email;
    const request =
      emailUsedInPayment || activeRegistrationOption === RegistrationOption.Email
        ? this.authApiService.resendEmailCode()
        : this.authApiService.resendPhoneCode();
    request.subscribe(
      () => {
        this.loading = false;
      },
      (error) => {
        Sentry.captureException(error.error || error, {
          tags: {
            module: 'extra',
            feature: 'auth-dialog',
          },
        });
        if (error?.error?.error?.code === 'rate_limited') {
          this.customErrors.rateLimited = true;
        }
        this.loading = false;
      }
    );
  }

  onCodeCompleted(e) {
    this.code = +e;
  }

  onCaptchaChange() {
    this.customErrors.emptyCaptcha = false;
    this.customErrors.invalidCaptcha = false;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
