import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DialogService } from './dialog.service';
import { CookiesDialogComponent, CookiesDialogComponentResp } from '../components/dialogs/cookies-dialog/cookies-dialog.component';
import { GaService } from './ga.service';
import { environment } from 'src/environments/environment';
import { isPlatformServer } from '@angular/common';
import { HotJarService } from './hot-jar.service';

@Injectable({
  providedIn: 'root',
})
export class CookiesService {
  constructor(
    private dialogService: DialogService,
    private gaService: GaService,
    private hotJarService: HotJarService,
    @Inject(PLATFORM_ID) private platformId
  ) {}

  init() {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    const cookiesAsked = localStorage.getItem('cookiesAccepted') !== null;
    if (!cookiesAsked) {
      this.openPopup();
    } else {
      const accepted = JSON.parse(localStorage.getItem('cookiesAccepted'));
      this.tryToInitTracking(accepted);
    }
  }

  openPopup() {
    const dialog = this.dialogService.openDialog<CookiesDialogComponent, CookiesDialogComponentResp>(CookiesDialogComponent, {
      disableClose: true,
    });
    dialog.afterClosed().subscribe((response) => {
      localStorage.setItem('cookiesAccepted', JSON.stringify(response.accepted));
      this.tryToInitTracking(response.accepted);
    });
  }

  private tryToInitTracking(accepted: boolean) {
    if (environment.production && accepted) {
      this.hotJarService.initialize();
      this.gaService.initialize();
    }
  }
}
