import { isPlatformServer } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, Input, Inject, PLATFORM_ID } from '@angular/core';
import { Validators, UntypedFormControl, UntypedFormGroup, FormControl } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import * as i18nIsoCountries from 'i18n-iso-countries';

import { Countries, Country } from 'src/app/home/interfaces/forms.interface';
import { DialogService } from 'src/app/home/services/dialog.service';
import { environment } from 'src/environments/environment';
import { LocaleService } from 'src/app/home/services/locale.service';
import { City } from 'src/app/home/interfaces/geocoder.interface';
import moment from 'moment';

@Component({
  selector: 'app-auth-form-update-details',
  templateUrl: './auth-form-update-details.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AuthFormUpdateDetailsComponent implements OnInit {
  @Input() data;

  form = new UntypedFormGroup({
    fb_id: new UntypedFormControl(''),
    fb_access_token: new UntypedFormControl(''),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    first_name: new UntypedFormControl('', [Validators.required]),
    last_name: new UntypedFormControl('', [Validators.required]),
    gender: new UntypedFormControl('', [Validators.required]),
    birth_date: new UntypedFormControl('', [Validators.required]),
    birth_date_dd: new UntypedFormControl(''),
    birth_date_mm: new UntypedFormControl(''),
    birth_date_yyyy: new UntypedFormControl(''),
    city: new UntypedFormControl('', [Validators.required]),
    country: new UntypedFormControl('', [Validators.required]),
    lat: new UntypedFormControl(0),
    lon: new UntypedFormControl(0),
    language: new UntypedFormControl('', [Validators.required]),
    captcha_token: new UntypedFormControl('', [Validators.required]),
  });

  loading = false;
  gender = null;

  customErrors = {
    emptyGender: false,
    emptyDate: false,
    userExist: false,
    invalidEmail: false,
  };

  maxDate = new Date();
  countries: Countries;
  assetsDir = '/';
  constructor(
    private translateService: TranslocoService,
    /*     private fbLoginService: FbLoginService, */
    private dialogService: DialogService,
    @Inject(PLATFORM_ID) private platformId: object,
    private localeService: LocaleService
  ) {}

  ngOnInit(): void {
    if (environment.production) {
      this.assetsDir = environment.deploy_url;
    }
    this.initCountries();
    if (this.data) {
      this.fillForm({
        ...this.data.data,
        fb_access_token: this.data.fb_access_token,
        fb_id: this.data.fb_id,
      });
      this.checkErrors(this.data.fields_errors);
    }
  }

  initCountries() {
    if (isPlatformServer(this.platformId)) {
      this.countries = {
        primary: [],
        other: [],
      };
      return;
    }
    this.localeService.withLocale(this.setCountries.bind(this));
  }
  setCountries(localeData: i18nIsoCountries.LocaleData, language: string): void {
    i18nIsoCountries.registerLocale(localeData);

    const countries: Countries = {
      primary: [],
      other: [],
    };

    const countriesArray = i18nIsoCountries.getNames(language);

    for (const key in countriesArray) {
      if (Object.prototype.hasOwnProperty.call(countriesArray, key)) {
        const country: Country = {
          code: key,
          name: countriesArray[key],
        };

        if (country.code === 'NL' || country.code === 'BE' || country.code === 'DE' || country.code === 'FR' || country.code === 'GB') {
          countries.primary.push(country);
        } else {
          countries.other.push(country);
        }
      }
    }

    countries.other.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });
    this.countries = countries;
  }

  checkErrors(errors) {
    if (errors.email?.indexOf('email_not_unique') > -1) {
      this.customErrors.userExist = true;
    }
  }

  fillForm(data) {
    Object.keys(this.form.controls).forEach((key) => {
      if (data[key]) {
        if (key === 'birth_date') {
          const date = new Date(data[key]);
          this.getFormControl(key).setValue(moment(date).format('YYYY-MM-DD'));
          this.getFormControl('birth_date_dd').setValue(moment(date).format('DD'));
          this.getFormControl('birth_date_mm').setValue(moment(date).format('MM'));
          this.getFormControl('birth_date_yyyy').setValue(moment(date).format('YYYY'));
        } else if ((data[key] === '1' || data[key] === '2') && key === 'gender') {
          this.gender = +data[key];
          this.getFormControl(key).setValue(+data[key]);
        } else {
          this.getFormControl(key).setValue(data[key]);
        }
      }
    });
  }

  getFormControl(name: string) {
    return this.form.get(name) as FormControl;
  }

  setGender(type: number) {
    this.form.get('gender').setValue(type);
    this.gender = type;
    this.customErrors.emptyGender = false;
  }

  dateChange($event) {
    this.getFormControl('birth_date').setValue(moment($event.value).format('YYYY-MM-DD'));
    this.getFormControl('birth_date_dd').setValue(moment($event.value).format('DD'));
    this.getFormControl('birth_date_mm').setValue(moment($event.value).format('MM'));
    this.getFormControl('birth_date_yyyy').setValue(moment($event.value).format('YYYY'));
    this.customErrors.emptyDate = false;
  }

  getLanguage(): string {
    return this.translateService.getDefaultLang() === 'en' ? 'en-GB' : 'nl-NL';
  }

  setCountry(city: City) {
    const country = city.country.toLocaleUpperCase();

    if (country && city.lat && city.lon) {
      this.getFormControl('country').setValue(country);
      this.getFormControl('lat').setValue(city.lat);
      this.getFormControl('lon').setValue(city.lon);
    }
  }

  onSubmit() {
    if (!this.form.get('gender').value) {
      this.customErrors.emptyGender = true;
    }
    if (!this.form.get('birth_date').value) {
      this.customErrors.emptyDate = true;
    }
    if (this.form.valid && !this.customErrors.emptyDate && !this.customErrors.emptyGender) {
      /*   
          const updateRequest: UpdateRequest = {
        fb_id: this.form.get('fb_id').value,
        fb_access_token: this.form.get('fb_access_token').value,
        data: this.form.getRawValue(),
      };
      this.fbLoginService.loginWithFacebook(updateRequest); */
      this.loading = true;
      this.dialogService.closeDialogs();
    }
  }
}
