import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subject, debounceTime, take, takeUntil } from 'rxjs';
import {
  RegistrationOption,
  AuthTypes,
  ValidateEmailRequest,
  ValidateEmailResponse,
  AuthDialogType,
} from 'src/app/home/interfaces/auth.interface';
import { AuthAPIService } from 'src/app/home/services/auth-api.service';
import { AuthService } from 'src/app/home/services/auth.service';
import { DialogService } from 'src/app/home/services/dialog.service';
import {
  ForgotPasswordDialogComponent,
  ForgotPasswordDialogComponentData,
} from '../forgot-password-dialog/forgot-password-dialog.component';
import * as Sentry from '@sentry/browser';
import { features } from 'process';

@Component({
  selector: 'app-auth-form-auth-id-types',
  templateUrl: './auth-form-auth-id-types.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AuthFormAuthIdTypesComponent implements OnInit, OnDestroy {
  @Output() countryPrefixChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() registrationOptionChange: EventEmitter<RegistrationOption> = new EventEmitter<RegistrationOption>();
  @Output() loadingChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() form: FormGroup;
  @Input() customErrors: { [key: string]: boolean };
  @Input() authType = AuthTypes.Login;

  activeRegistrationOption = RegistrationOption.Email;
  countryPrefix: string;
  destroy$ = new Subject<boolean>();

  constructor(
    public dialog: MatDialog,
    private recaptchaV3Service: ReCaptchaV3Service,
    private authApiService: AuthAPIService,
    private authService: AuthService,
    private dialogService: DialogService,
    private dialogRef: MatDialog
  ) {}

  ngOnInit() {
    if (this.authType === AuthTypes.Register) {
      this.listenForEmailChanges();
    }
  }

  removeUppercase() {
    const control = this.form.get('email');
    control.setValue(this.form.get('email').value.toLowerCase());
  }

  listenForEmailChanges() {
    this.getFormControl('email')
      .valueChanges.pipe(takeUntil(this.destroy$), debounceTime(400))
      .subscribe((value) => {
        if (value && this.getFormControl('email')?.valid) {
          this.validateEmail();
        }
      });
  }

  validateEmail() {
    if (this.getFormControl('email')?.value) {
      if (this.getFormControl('email')?.valid) {
        this.loadingChange.emit(true);
        this.recaptchaV3Service
          .execute('validateEmail')
          .pipe(take(1))
          .subscribe(
            (token) => {
              const body: ValidateEmailRequest = {
                email: this.getFormControl('email').value?.substring(this.countryPrefix?.length),
                captcha_token: token,
              };

              this.authApiService.validateEmail(body).subscribe((res: ValidateEmailResponse) => {
                if (res?.code === 'user_exists') {
                  this.getFormControl('email')?.setErrors({ user_exists: true });
                }
                if (res?.code === 'not_deliverable') {
                  this.getFormControl('email')?.setErrors({ not_deliverable: true });
                }

                this.getFormControl('email').markAsTouched();
                this.loadingChange.emit(false);
              });
            },
            (error) => {
              Sentry.captureException(error.error || error, {
                tags: {
                  module: 'extra',
                  feature: 'auth-form-auth-id-types',
                },
              });
            }
          );
      }
    }
  }

  toggleRegistrationOption(registrationOption: RegistrationOption) {
    this.activeRegistrationOption = registrationOption;
    this.registrationOptionsAsArray.forEach((option) => {
      if (option !== this.activeRegistrationOption) {
        this.form.removeControl(option);
      }

      this.form.removeControl(option);
    });

    if (registrationOption !== RegistrationOption.Phone) {
      this.form.removeControl('phone_country_code');
    }

    if (registrationOption === RegistrationOption.Phone) {
      this.form.addControl('phone_country_code', new FormControl(this.countryPrefix));
      this.form.get('phone_country_code').updateValueAndValidity();
    }

    if (!this.form.get(this.activeRegistrationOption)) {
      this.form.addControl(this.activeRegistrationOption, new FormControl('', Validators.required));
    }

    if (registrationOption === RegistrationOption.Email) {
      this.form.get(registrationOption).addValidators(Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'));
    }

    this.form.get(this.activeRegistrationOption).updateValueAndValidity();
    this.registrationOptionChange.emit(registrationOption);
  }

  openForgotPasswordDialog() {
    const email = this.form.controls.email.value;
    this.dialogRef.closeAll();
    this.dialogService.openDialog<ForgotPasswordDialogComponentData>(ForgotPasswordDialogComponent, { data: { email } });
  }

  getFormControl(name: string) {
    if (this.form?.get(name)) {
      return this.form.get(name) as FormControl;
    }
  }

  onCountryPrefixChange(countryPrefix: string) {
    this.form.get('phone_country_code').setValue(countryPrefix);
    this.countryPrefixChange.emit(countryPrefix);
  }

  openLoginDialog() {
    this.dialog.closeAll();

    this.authService.openAuthDialog({
      data: {
        type: AuthDialogType.Login,
      },
    });
  }

  get registrationOptions() {
    return RegistrationOption;
  }

  get registrationOptionsAsArray(): RegistrationOption[] {
    return Object.values(RegistrationOption);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
