import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Shop } from '../interfaces/shop.interface';
import { ShoppingCart } from '../interfaces/shopping-cart.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { ShopApiService } from './shop-api.service';

@Injectable()
export class ShopService {
  shop: Shop;
  private _shoppingCart: ShoppingCart;
  private _shoppingCartBS = new BehaviorSubject<ShoppingCart>(null);
  shoppingCart$ = this._shoppingCartBS.asObservable();

  public get shoppingCart(): ShoppingCart {
    return this._shoppingCart;
  }
  public set shoppingCart(v: ShoppingCart) {
    this._shoppingCart = v;
    this._shoppingCartBS.next(v);
  }
  private _source;
  public get source(): string {
    return this._source;
  }
  public set source(v: string) {
    this._source = v;
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.setItem('source', v);
    }
  }

  private _campaign: string;
  public get campaign(): string {
    return this._campaign;
  }

  public set campaign(v: string) {
    this._campaign = v;
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.setItem('campaign', v);
    }
  }
  facebookRedirect: boolean;
  appicRedirect: boolean;
  paymentRedirect: boolean;
  summaryPage: boolean;
  serverSessionUuid: string;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private shopApiService: ShopApiService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this._source = sessionStorage.getItem('source');
      this._campaign = sessionStorage.getItem('campaign');
    }
  }

  getQueryParams(additionalParams?: Record<string, string>) {
    const queryParams = {
      transaction: this.shoppingCart.uuid,
      ...additionalParams,
    };

    Object.keys(queryParams).forEach((key) => (queryParams[key] === null || queryParams[key] === undefined) && delete queryParams[key]);

    return queryParams;
  }

  initShoppingCart(shoppingCartUuid: string, errFb: () => void): Observable<ShoppingCart> {
    if (!this._shoppingCart) {
      this.shopApiService.getShoppingCart(shoppingCartUuid).subscribe((cart) => {
        this.shoppingCart = cart;
      }),
        errFb();
    }
    return this.shoppingCart$;
  }
}
