<div class="loading-container" *ngIf="loading">
  <div class="spinner-container">
    <mat-spinner class="primary" [diameter]="40"></mat-spinner>
  </div>
</div>
<div class="row auth-form" [ngClass]="{ loading: loading }">
  <div class="col-12">
    <form class="auth-form" [formGroup]="form">
      <!-- EMAIL -->
      <div class="form-field-container">
        <div class="form-field-label">
          {{ 'form.field.email' | transloco }}
        </div>
        <mat-form-field appearance="outline" class="form-field" floatLabel="auto">
          <input matInput placeholder="{{ 'form.field.email' | transloco }}" formControlName="email" required />
          <mat-error *ngIf="getFormControl('email').invalid">
            <ng-container *ngIf="getFormControl('email').errors.required">
              {{ 'form.error.required' | transloco }}
            </ng-container>
            <ng-container *ngIf="getFormControl('email').errors.email">
              {{ 'form.error.invalid_email' | transloco }}
            </ng-container>
          </mat-error>
        </mat-form-field>
        <div class="custom-error" *ngIf="customErrors.userExist || customErrors.invalidEmail">
          <ng-container *ngIf="customErrors.invalidEmail">
            {{ 'form.error.invalid_email' | transloco }}
            <ng-container *ngIf="customErrors.userExist">,</ng-container>
          </ng-container>
          <ng-container *ngIf="customErrors.userExist">{{ 'form.error.user_already_exist' | transloco }}</ng-container>
        </div>
      </div>

      <!-- FIRST NAME -->
      <div class="form-field-container">
        <div class="form-field-label">
          {{ 'form.field.first_name' | transloco }}
        </div>
        <mat-form-field appearance="outline" class="form-field" floatLabel="auto">
          <input matInput placeholder="{{ 'form.field.first_name' | transloco }}" formControlName="first_name" required />
          <mat-error *ngIf="getFormControl('first_name').invalid">
            <ng-container *ngIf="getFormControl('first_name').errors.required">
              {{ 'form.error.required' | transloco }}
            </ng-container>
          </mat-error>
        </mat-form-field>
      </div>

      <!-- LAST NAME -->
      <div class="form-field-container">
        <div class="form-field-label">
          {{ 'form.field.last_name' | transloco }}
        </div>
        <mat-form-field class="form-field" floatLabel="auto">
          <input matInput placeholder="{{ 'form.field.last_name' | transloco }}" formControlName="last_name" required />
          <mat-error *ngIf="getFormControl('last_name').invalid">
            <ng-container *ngIf="getFormControl('last_name').errors.required">
              {{ 'form.error.required' | transloco }}
            </ng-container>
          </mat-error>
        </mat-form-field>
      </div>

      <!-- GENDER -->
      <div class="form-field-container">
        <div class="form-field-label">
          {{ 'form.field.gender' | transloco }}
        </div>
        <div class="row">
          <div class="col-6 gender-btn-container">
            <button
              class="btn btn-gender d-block w-100 male"
              [ngClass]="{ active: gender === 1 }"
              (click)="setGender(1); $event.preventDefault()"
            >
              <svg-icon class="btn-icon" src="{{ assetsDir }}assets/icons/icon-male.svg" />

              {{ 'form.field.male' | transloco }}
            </button>
          </div>
          <div class="col-6 gender-btn-container">
            <button
              class="btn btn-gender d-block w-100 female"
              [ngClass]="{ active: gender === 2 }"
              (click)="setGender(2); $event.preventDefault()"
            >
              <svg-icon class="btn-icon" src="{{ assetsDir }}assets/icons/icon-female.svg" />

              {{ 'form.field.female' | transloco }}
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12 custom-error p-0" *ngIf="customErrors.emptyGender">
            {{ 'form.error.required' | transloco }}
          </div>
        </div>
      </div>

      <!-- DATE OF BIRTH -->
      <div class="form-field-container">
        <div class="form-field-label">
          {{ 'form.field.date_of_birth' | transloco }}
        </div>
        <div class="row">
          <div class="col-4 col-md-3 date-input-container">
            <mat-form-field appearance="outline" class="form-field date-input" floatLabel="auto">
              <input matInput placeholder="DD" formControlName="birth_date_dd" [readonly]="true" />
            </mat-form-field>
          </div>
          <div class="col-4 col-md-3 date-input-container">
            <mat-form-field appearance="outline" class="form-field date-input" floatLabel="auto">
              <input matInput placeholder="MM" formControlName="birth_date_mm" [readonly]="true" />
            </mat-form-field>
          </div>
          <div class="col-4 col-md-3 date-input-container">
            <mat-form-field appearance="outline" class="form-field date-input" floatLabel="auto">
              <input matInput placeholder="YYYY" formControlName="birth_date_yyyy" [readonly]="true" />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12 custom-error p-0" *ngIf="customErrors.emptyDate">
            {{ 'form.error.required' | transloco }}
          </div>
        </div>
      </div>

      <!-- CITY -->
      <div class="form-field-container">
        <div class="form-field-label">
          {{ 'form.field.city' | transloco }}
        </div>
        <app-city-selector [control]="getFormControl('city')" (cityChange)="setCountry($event)"></app-city-selector>
      </div>

      <!-- COUNTRY -->
      <div class="form-field-container">
        <div class="form-field-label">
          {{ 'form.field.country' | transloco }}
        </div>
        <mat-form-field appearance="outline" class="form-field" floatLabel="auto">
          <select matNativeControl placeholder="{{ 'form.field.country' | transloco }}" formControlName="country" required>
            <option *ngFor="let country of countries?.primary" [value]="country.code">{{ country.name }}</option>
            <option disabled></option>
            <option *ngFor="let country of countries?.other" [value]="country.code">{{ country.name }}</option>
          </select>
          <mat-error *ngIf="getFormControl('country').invalid">
            <ng-container *ngIf="getFormControl('country').errors.required">
              {{ 'form.error.required' | transloco }}
            </ng-container>
          </mat-error>
        </mat-form-field>
      </div>

      <button class="btn btn-primary d-block w-100 btn-submit mb-3 mt-3" (click)="onSubmit()">
        {{ 'global.update' | transloco }}
      </button>
    </form>
  </div>
</div>
