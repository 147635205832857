<app-dialog-layout-inner-x (close)="accept()" [title]="'cookies.cookies' | transloco">
  <ng-template #content>
    <p class="description mb-0">{{ 'cookies.this_websites_uses_cookie' | transloco }}</p>
    <p class="description mb-4">{{ 'cookies.we_assume_you' | transloco }}</p>
  </ng-template>
  <ng-template #actions>
    <div class="btn-container">
      <button class="btn btn-secondary" (click)="accept()">{{ 'cookies.accept_all' | transloco }}</button>
      <button class="btn btn-grey" (click)="reject()">{{ 'cookies.reject' | transloco }}</button>
    </div>
  </ng-template>
</app-dialog-layout-inner-x>
