import { Injectable } from '@angular/core';
import { User } from '../interfaces/user.interface';
import { AuthAPIService } from './auth-api.service';
import { BehaviorSubject, catchError, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserSessionService {
  user$ = new BehaviorSubject<User>(null);

  public get user(): User {
    return this.user$.value;
  }

  public set user(v: User) {
    this.user$.next(v);
  }
  private _order_uuid: string;

  public get order_uuid(): string {
    return this._order_uuid;
  }

  public set order_uuid(v: string) {
    this._order_uuid = v;
  }

  private _disableGetProfileInit = new BehaviorSubject(false);
  disableGetProfileInit$ = this._disableGetProfileInit.asObservable();
  get disableGetProfileInit() {
    return this._disableGetProfileInit.value;
  }
  set disableGetProfileInit(disableGetProfileInit: boolean) {
    this._disableGetProfileInit.next(disableGetProfileInit);
  }

  constructor(private authApiService: AuthAPIService) {}
  getProfile() {
    return this.authApiService.profile().pipe(
      tap((profileResponse) => {
        this.user = profileResponse;
      }),
      catchError(() => {
        this.user = null;
        return of(null);
      })
    );
  }
}
