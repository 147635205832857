<div class="panel w-100">
  <app-navigation [shop]="shop" [shopUrl]="nameShop"></app-navigation>
  <ng-container *ngIf="areAllProductsSoldOut || !tickets.length">
    <p class="no-products-info">
      <span>{{ 'product.no_products' | transloco }}</span>
      <br />
      <span>{{ 'product.no_products_2' | transloco }}</span>
    </p>
  </ng-container>
  <div class="products-container">
    <div class="div" *ngIf="!loading; else spinner">
      <div class="product-divider"></div>
      <ng-container *ngFor="let ticket of tickets">
        <div class="product-divider"></div>
        <app-product-ticket [busy]="busy" [ticketNumber]="checkNumber(ticket.uuid)" [ticket]="ticket" [color]="color"></app-product-ticket>
        <ng-container *ngFor="let futureTicket of ticket.future_prices">
          <app-product-ticket-future
            [busy]="true"
            [ticket]="futureTicket"
            [eventName]="ticket.event_name"
            [color]="color"
          ></app-product-ticket-future>
          <div class="product-divider"></div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #spinner>
  <div class="spinner-container">
    <mat-spinner class="primary" [diameter]="40"></mat-spinner>
  </div>
</ng-template>
