import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeHourMinSec',
})
export class TimeHourMinSecPipe implements PipeTransform {
  transform(value: number) {
    const hoursRemaining = Math.floor(value / 3600);
    const minutesRemaining = Math.floor((value % 3600) / 60);
    const secondsRemaining = value % 60;
    const hours = this.patchWithZero(hoursRemaining);
    const minutes = this.patchWithZero(minutesRemaining);
    const seconds = this.patchWithZero(secondsRemaining);

    return `${hours}:${minutes}:${seconds}`;
  }

  patchWithZero(value: number): string {
    if (value < 10) {
      return `0${value}`;
    } else {
      return `${value}`;
    }
  }
}
