import { isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of, Subscription, throwError, timer } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AppComponent } from 'src/app/app.component';
import { ShopService } from '../../services/shop.service';
import { VisibilityChangeService } from '../../services/visibility-change.service';
import { OrderSummaryService } from '../../services/order-summary.service';
import { environment } from 'src/environments/environment.prod';
import { ShoppingCart } from '../../interfaces/shopping-cart.interface';
import { OrderPanelOptions } from '../../interfaces/component.interface';
import { ShopApiService } from '../../services/shop-api.service';
import * as Sentry from '@sentry/browser';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss'],
})
export class OrderSummaryComponent implements OnDestroy, OnInit {
  @Output() nextStepEmiter = new EventEmitter();
  shoppingCart$ = this.shopService.shoppingCart$.pipe(
    tap((shoppingCart) => {
      this.setTimer(shoppingCart);
    })
  );
  timerSub: Subscription;
  expireTimer: number;
  assetsDir = '/';
  expand = false;
  objectKeys = Object.keys;
  warningColor: boolean;
  options$ = this.orderSummaryService.options$;
  constructor(
    private appComponent: AppComponent,
    private route: ActivatedRoute,
    private router: Router,
    private shopService: ShopService,
    @Inject(PLATFORM_ID) private platformId: object,
    private visibilityChangeService: VisibilityChangeService,
    private orderSummaryService: OrderSummaryService,
    private shopApiService: ShopApiService
  ) {}

  ngOnInit(): void {
    this.warningColor = false;
    if (environment.production) {
      this.assetsDir = environment.deploy_url;
    }
    if (isPlatformBrowser(this.platformId)) {
      this.visibilityChangeService.docHidden$
        .pipe(
          filter((v) => !v),
          switchMap(() => {
            if (this.shopService.shoppingCart) {
              return this.shopApiService.getShoppingCart(this.shopService.shoppingCart.uuid, true);
            } else {
              return of(null);
            }
          }),
          catchError((e) => {
            return throwError(() => e);
          })
        )
        .subscribe(
          (cart) => {
            if (cart?.status === 'expired') {
              this.expireTime();
            }
          },
          (error) => {
            Sentry.captureException(error.error || error, {
              tags: {
                feature: 'order-summary',
              },
            });
          }
        );
    }
  }

  setTimer(shoppingCart: ShoppingCart) {
    if (shoppingCart) {
      if (shoppingCart.summary) {
        const now = new Date(shoppingCart.server_time);
        const exp = new Date(shoppingCart.reserved_until.replace(/-/g, '/'));
        const seconds = Math.floor((+exp - +now) / 1000);
        if (exp > now) {
          this.startTimer(seconds);
        }
      }

      if (!this.shopService.shoppingCart.summary.products.length && this.timerSub) {
        this.timerSub.unsubscribe();
      }
    } else if (this.timerSub) {
      this.timerSub.unsubscribe();
    }
  }

  public startTimer(seconds) {
    if (isPlatformBrowser(this.platformId)) {
      if (this.timerSub) {
        this.timerSub.unsubscribe();
      }
      this.timerSub = timer(0, 1000)
        .pipe(
          take(seconds + 1),
          map((value) => seconds - value)
        )
        .subscribe({
          next: (value) => {
            this.expireTimer = value;
            if (!this.warningColor && this.expireTimer < 60) {
              this.warningColor = true;
            }
            if (this.expireTimer === 0) {
              this.expireTime();
            }
          },
        });
    }
  }

  private expireTime() {
    this.shopService.shoppingCart = null;
    this.warningColor = false;
    this.appComponent.openDialog(
      'application_errors.time_for_ordering_tickets_was_expired',
      (callback) => {
        if (callback) {
          this.router.navigate(['./tickets'], {
            relativeTo: this.route,
          });
        }
      },
      null,
      'application_errors.time_expired'
    );
  }

  goNextStep(options: OrderPanelOptions) {
    if (options?.nextStep) {
      this.nextStepEmiter.emit();
      if (options.nextStep === 'payment') {
        this.orderSummaryService.nextStepClicked = true;
      } else if (options.nextStep === 'submitOrder') {
        options.nextButtonDisabled = true;
        setTimeout(() => {
          options.nextButtonDisabled = false;
        }, 4000);
        this.orderSummaryService.submitOrder = true;
      } else {
        this.router.navigate([`./${options.nextStep}`], {
          relativeTo: this.route,
          queryParams: this.shopService.getQueryParams(options.additionalParams || {}),
        });
      }
    }
  }

  ngOnDestroy() {
    if (this.timerSub) {
      this.timerSub.unsubscribe();
    }
  }
}
