import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subject, takeUntil } from 'rxjs';
import { AuthAPIService } from 'src/app/home/services/auth-api.service';
import { DialogService } from 'src/app/home/services/dialog.service';
import { ShopService } from 'src/app/home/services/shop.service';
import { UserSessionService } from 'src/app/home/services/user-session.service';
import { regexValidatorFunction } from 'src/app/utils/matchOtherValidator';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/home/services/auth.service';
import * as Sentry from '@sentry/browser';

@Component({
  selector: 'app-auth-form-password',
  templateUrl: './auth-form-password.component.html',
  styleUrls: ['./auth-form-password.component.scss', '../auth-dialog.component.scss'],
})
export class AuthFormPasswordComponent implements OnInit {
  @Output() stepChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() passwordForm: FormGroup;
  @Input() signUpWithForm: FormGroup;

  hidePassword = true;
  capsOn;
  loading = false;
  destroy$ = new Subject<boolean>();
  passwordValidators = [];
  passwordConditions = [];

  customErrors = {
    emptyCaptcha: false,
    invalidCaptcha: false,
    invalidEmail: false,
    userExists: false,
  };

  recaptchaKey;
  assetsDir = '/';
  constructor(
    private authApiService: AuthAPIService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private dialogService: DialogService,
    private shopService: ShopService,
    private userSessionService: UserSessionService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    if (environment.production) {
      this.assetsDir = environment.deploy_url;
    }
    this.authApiService.getPasswordConditions().subscribe(
      (response) => {
        for (const condition of response.conditions) {
          this.passwordValidators.push(regexValidatorFunction(condition));
        }
        this.passwordConditions = response.conditions;
        this.passwordForm.get('password').addValidators(this.passwordValidators);
      },
      (error) => {
        Sentry.captureException(error.error || error, {
          tags: {
            feature: 'auth-form-password',
          },
        });
      }
    );
  }

  getFormControl(name: string) {
    if (this.passwordForm.get(name)) {
      return this.passwordForm.get(name);
    }
  }

  getErrorKeys(controlName: string): string[] {
    const control = this.getFormControl(controlName);
    return Object.keys(control.errors);
  }

  executeCaptcha() {
    this.passwordForm.markAllAsTouched();
    this.passwordForm.updateValueAndValidity();

    if (this.passwordForm.valid) {
      const observer = {
        next: (token) => {
          this.onCaptchaChange();
          this.getFormControl('captcha_token').setValue(token);
          this.onSubmit();
        },
      };

      this.recaptchaV3Service.execute('register').pipe(takeUntil(this.destroy$)).subscribe(observer);
    }
  }

  onSubmit() {
    if (!this.passwordForm.get('captcha_token').value) {
      this.customErrors.emptyCaptcha = true;
    }

    if ((this.passwordForm.valid || !this.passwordForm.errors) && !this.customErrors.emptyCaptcha) {
      const registerRequest = { ...this.passwordForm.getRawValue(), ...this.signUpWithForm.getRawValue() };
      if (this.signUpWithForm.get('phone_number')?.value) {
        const countryPrefix = this.signUpWithForm.get('phone_country_code')?.value;
        registerRequest.phone_number = this.signUpWithForm.get('phone_number').value?.substring(countryPrefix?.length);
      }

      this.register(registerRequest);
    }
  }

  register(registerRequest) {
    this.customErrors.invalidEmail = false;
    this.customErrors.userExists = false;
    this.loading = true;
    const context = this.userSessionService.order_uuid
      ? { order_uuid: this.userSessionService.order_uuid }
      : this.shopService.shoppingCart
        ? { shopping_cart_uuid: this.shopService.shoppingCart.uuid }
        : null;
    this.authApiService.register({ ...registerRequest, context }).subscribe(
      () => {
        this.stepChange.emit();
      },
      (error) => {
        const err = error?.error?.fields_errors;
        if (error?.error?.captcha_token) {
          this.customErrors.invalidCaptcha = true;
        } else if (err) {
          Sentry.captureException(error.error || error, {
            tags: {
              feature: 'register',
            },
          });
          if (err.email) {
            if (err.email.find((obj) => obj === 'user_already_exists')) {
              this.customErrors.userExists = true;
            } else if (err.email.find((obj) => obj === 'invalid_value')) {
              this.customErrors.invalidEmail = true;
            }
          }
        }
        this.loading = false;
      }
    );
  }

  onCaptchaChange() {
    this.customErrors.emptyCaptcha = false;
    this.customErrors.invalidCaptcha = false;
  }

  isConditionMet(condition) {
    const regex = new RegExp(condition.contains_regex_pattern);
    return regex.test(this.passwordForm.get('password')?.value);
  }
}
