import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HotJarService {
  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  public initialize() {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    try {
      if (environment.hotJarId) {
        ((h, o, t, j, a, r) => {
          h.hj =
            h.hj ||
            function () {
              (h.hj.q = h.hj.q || []).push(arguments);
            };
          h._hjSettings = { hjid: environment.hotJarId, hjsv: 6 };
          a = o.getElementsByTagName('head')[0];
          r = o.createElement('script');
          r.async = 1;
          r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
          a.appendChild(r);
        })(window as any, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
      }
    } catch (e) {
      console.error('Error adding Hot Jar', e);
    }
  }
}
