import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dialog-layout-inner-x',
  templateUrl: './dialog-layout-inner-x.component.html',
  styleUrls: ['./dialog-layout-inner-x.component.scss'],
})
export class DialogLayoutInnerXComponent implements OnInit {
  @Input() title: string | undefined;
  @Input() btnText = '';
  @Input() pageIndicator = '';
  @Input() disableSubmit = false;
  @Input() minHeight = null;
  @Input() dialogWarning: string | null;
  @Input() closeIconPath: string;
  @Input() borderRadius: string;
  @Input() closeIconVisible = true;
  @ContentChild('content') content!: TemplateRef<any>;
  @ContentChild('actions') actions!: TemplateRef<any>;
  assetsDir = '';
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close = new EventEmitter();
  @Output() confirm = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    if (environment.production) {
      this.assetsDir = environment.deploy_url;
    }
  }

  closeDialog() {
    this.close.emit();
  }

  handleConfirm() {
    this.confirm.emit();
  }
}
