import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeDayHourMinSec',
})
export class TimeDayHourMinSecPipe implements PipeTransform {
  transform(value: number) {
    const daysRemaining = Math.floor(value / 86400);
    const hoursRemaining = Math.floor((value % 86400) / 3600);
    const minutesRemaining = Math.floor((value % 3600) / 60);
    const secondsRemaining = value % 60;
    const days = this.patchWithZero(daysRemaining);
    const hours = this.patchWithZero(hoursRemaining);
    const minutes = this.patchWithZero(minutesRemaining);
    const seconds = this.patchWithZero(secondsRemaining);

    return `${days}d : ${hours}h : ${minutes}m : ${seconds}s`;
  }

  patchWithZero(value: number): string {
    if (value < 10) {
      return `0${value}`;
    } else {
      return `${value}`;
    }
  }
}
