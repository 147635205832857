import { HostListener, Directive } from '@angular/core';
import { ShopApiService } from '../services/shop-api.service';
import { ShopService } from '../services/shop.service';

@Directive()
export abstract class ComponentCanDeactivate {
  abstract canDeactivate(): boolean;

  constructor(
    protected shopApiService: ShopApiService,
    protected shopService: ShopService
  ) {}

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (!this.canDeactivate()) {
      $event.returnValue = true;
    }
  }
  /* 
 commented out due to poor browser support and random cart cancellation
  @HostListener('window:unload', ['$event'])
  unload($event: any) {
    if (!this.canDeactivate() && this.shopService.shoppingCart) {
      this.shopApiService.deleteShoppingCart(this.shopService.shoppingCart.uuid).subscribe(
        () => {
          this.shopService.shoppingCart = undefined;
        },
        (error) => {
          console.error(error);
        }
      );
    }
  } */
}
