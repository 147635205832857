import { CommonModule, DatePipe } from '@angular/common';
import { Injectable, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { CodeInputModule } from 'angular-code-input';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input-v16';
import { MaterialModule } from 'src/app/material.module';
import { AuthDialogComponent } from './auth-dialog/auth-dialog.component';
import { AuthFormAuthIdTypesComponent } from './auth-dialog/auth-form-auth-id-types/auth-form-auth-id-types.component';
import { AuthFormConfirmationCodeComponent } from './auth-dialog/auth-form-confirmation-code/auth-form-confirmation-code.component';
import { AuthFormLoginComponent } from './auth-dialog/auth-form-login/auth-form-login.component';
import { AuthFormPasswordComponent } from './auth-dialog/auth-form-password/auth-form-password.component';
import { AuthFormResetComponent } from './auth-dialog/auth-form-reset/auth-form-reset.component';
import { AuthFormSignUpWithComponent } from './auth-dialog/auth-form-sign-up-with/auth-form-sign-up-with.component';
import { AuthFormUpdateDetailsComponent } from './auth-dialog/auth-form-update-details/auth-form-update-details.component';
import { AuthFormUserDetailsComponent } from './auth-dialog/auth-form-user-details/auth-form-user-details.component';
import { ForgotPasswordDialogComponent } from './auth-dialog/forgot-password-dialog/forgot-password-dialog.component';
import { CitySelectorModule } from './city-selector/city-selector.module';
import { CountryPhonePrefixSelectorComponent } from './country-phone-prefix-selector/country-phone-prefix-selector.component';
import { CreateAccountFromPasswordComponent } from './create-account-from-password/create-account-from-password.component';
import { DialogLayoutInnerXComponent } from './dialogs/dialog-layout-inner-x/dialog-layout-inner-x.component';
import { ErrorDialogComponent } from './dialogs/error-dialog/error-dialog.component';
import { LoggedDialogComponent } from './dialogs/logged-dialog/logged-dialog.component';
import { AppicUpdatePasswordFormComponent } from './forms/appic-update-password-form/appic-update-password-form.component';
import { DetailsFormComponent } from './forms/details-form/details-form.component';
import { ResetPasswordFormComponent } from './forms/password-reset-form/password-reset-form.component';
import { GlobalNavigationComponent } from './global-navigation/global-navigation.component';
import { LoaderComponent } from './loader/loader.component';
import { NavigationComponent } from './navigation/navigation.component';
import { OrderSummaryUpcomingComponent } from './order-summary/order-summary-upcoming/order-summary-upcoming.component';
import { OrderSummaryComponent } from './order-summary/order-summary.component';
import { PaymentMethodComponent } from './payment-method/payment-method.component';
import { PaymentVariantComponent } from './payment-method/payment-variant.component';
import { TimeDaysPipe } from './pipes/time-days.pipe';
import { TimeHourMinSecPipe } from './pipes/time-hour-min-sec.pipe';
import { TimeMinSecPipe } from './pipes/time-min-sec.pipe';
import { ProductTicketFutureComponent } from './product-ticket/future/product-ticket-future.component';
import { ProductTicketComponent } from './product-ticket/product-ticket.component';
import { ProductTicketUpcomingComponent } from './product-ticket/upcoming/product-ticket-upcoming.component';
import { WinTicketComponent } from './product-ticket/win-ticket/win-ticket.component';
import { RadioBtnCheckComponent } from './radio-btn-check/radio-btn-check.component';
import { StepsComponent } from './steps/steps.component';
import { TimeDayHourMinSecPipe } from './pipes/time-day-hour-min-sec.pipe';
import { NestedDropdownComponent } from './nested-dropdown/nested-dropdown.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { CookiesDialogComponent } from './dialogs/cookies-dialog/cookies-dialog.component';
import { InfoSnackbarComponent } from './snackbar/info-snackbar/info-snackbar.component';
import { ErrorSnackbarComponent } from './snackbar/error-snackbar/error-snackbar.component';
import { SuccessSnackbarComponent } from './snackbar/success-snackbar/success-snackbar.component';
import { FooterComponent } from './footer/footer.component';

const MY_DATE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Injectable()
export class PickDateAdapter extends NativeDateAdapter {}
const DIRECTIVES = [ClickOutsideDirective];
const COMPONENTS = [
  ErrorDialogComponent,
  LoggedDialogComponent,
  LoaderComponent,
  NavigationComponent,
  NestedDropdownComponent,
  OrderSummaryComponent,
  ProductTicketComponent,
  StepsComponent,
  TimeMinSecPipe,
  TimeHourMinSecPipe,
  AppicUpdatePasswordFormComponent,
  DetailsFormComponent,
  ResetPasswordFormComponent,
  PaymentMethodComponent,
  PaymentVariantComponent,
  ProductTicketUpcomingComponent,
  OrderSummaryUpcomingComponent,
  TimeDaysPipe,
  TimeDayHourMinSecPipe,
  ProductTicketFutureComponent,
  GlobalNavigationComponent,
  DialogLayoutInnerXComponent,
  RadioBtnCheckComponent,
  CreateAccountFromPasswordComponent,
  AuthDialogComponent,
  AuthFormResetComponent,
  AuthFormUpdateDetailsComponent,
  AuthFormAuthIdTypesComponent,
  AuthFormSignUpWithComponent,
  AuthFormConfirmationCodeComponent,
  AuthFormPasswordComponent,
  AuthFormUserDetailsComponent,
  AuthFormLoginComponent,
  ForgotPasswordDialogComponent,
  CountryPhonePrefixSelectorComponent,
  WinTicketComponent,
  CookiesDialogComponent,
  InfoSnackbarComponent,
  ErrorSnackbarComponent,
  SuccessSnackbarComponent,
  FooterComponent,
];
@NgModule({
  declarations: [...COMPONENTS, ...DIRECTIVES],
  imports: [
    TranslocoModule,
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    MatTooltipModule,
    RouterModule,
    FormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    AngularSvgIconModule,
    CodeInputModule,
    NgxMatIntlTelInputComponent,
    CitySelectorModule,
  ],
  exports: [...COMPONENTS, ...DIRECTIVES, CitySelectorModule],
  providers: [
    DatePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT },
  ],
})
export class ExtraComponentModule {}
