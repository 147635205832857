import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CaptchaService {
  private renderer: Renderer2;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  setCaptchaVisibility(visibility: 'visible' | 'hidden', instant = true) {
    let count = 0;
    const interval = setInterval(
      () => {
        const badge = this.document.querySelector('.grecaptcha-badge');
        if (badge) {
          this.renderer.setStyle(badge, 'visibility', visibility);
          clearInterval(interval);
        }
        if (count >= 5) {
          clearInterval(interval);
        }
        count++;
      },
      instant ? 0 : 500
    ); /* it takes some time for captcha to load */
  }
}
