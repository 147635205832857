import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

import { SnackbarData } from '../../../services/snackbar.service';

@Component({
  selector: 'lib-success-snackbar',
  templateUrl: './success-snackbar.component.html',
  styleUrls: ['./success-snackbar.component.scss', '../snackbar.scss'],
})
export class SuccessSnackbarComponent implements OnInit {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackbarData,
    private snackBarRef: MatSnackBarRef<SuccessSnackbarComponent>
  ) {}

  ngOnInit(): void {}

  close() {
    Inject;
    this.snackBarRef.dismiss();
  }
}
