<!--AVAILABLE-->
<div class="product">
  <div class="row align-items-center">
    <div class="col order-sm-1">
      <div class="product-name">
        <h6>{{ ticket.event_name }} {{ ticket.event_name ? '-' : '' }} {{ ticket.title }}</h6>
      </div>
      <div class="product-price">
        {{ ticket.price | currency: ticket.currency }}
        <span class="fee"
          >({{ 'product.inc' | transloco }} {{ ticket.service_fee | currency: ticket.currency }} {{ 'product.fee' | transloco }})</span
        >
      </div>
    </div>
    <div class="col-12 order-sm-3 mt-3 align-top">
      <div
        class="product-description"
        [innerHTML]="ticket.description"
        #productDescription
        tabindex="0"
        [ngClass]="{ collapsed: !expand, 'p-dots': isDescriptionCollapsed }"
        (keydown)="expand = expand ? false : true"
        (click)="expand = expand ? false : true"
      ></div>
      <button class="btn btn-more" *ngIf="isDescriptionCollapsed" (click)="expand = expand ? false : true">
        <span>{{ expand ? ('product.less' | transloco) : ('product.more' | transloco) }}</span>
        <i class="material-icons">{{ expand ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</i>
      </button>
    </div>
  </div>
</div>
