export const RESERVATION_RESPONSE_SUCCESS = 'success';
export const RESERVATION_RESPONSE_MAXIMUM_QUANTITY_REACHED = 'maximum_quantity_has_been_reached';
export const RESERVATION_RESPONSE_ORDER_ALREADY_CREATED = 'order_for_this_shopping_cart_already_created';
export const RESERVATION_RESPONSE_NOT_FOUND = 'not_found';
export const RESERVATION_RESPONSE_INTERNAL_ERROR = 'internal_error';
export const RESERVATION_RESPONSE_SHOPPING_CART_CANCELLED = 'shopping_cart_is_cancelled';
export const RESERVATION_RESPONSE_TICKET_HANDLER_NOT_RESPONDING = 'ticket_handler_not_responding';
export const RESERVATION_CANCELED_BY_USER = '';

export const BUYER_DETAILS_RESPONSE_NOT_FOUND = 'not_found';
export const BUYER_DETAILS_RESPONSE_SHOPPING_CART_EXPIRED = 'shopping_cart_expired';
export const BUYER_DETAILS_RESPONSE_SHOPPING_CART_CANCELED = 'shopping_cart_cancelled';
export const BUYER_DETAILS_RESPONSE_ORDER_CREATED = 'order_for_this_shopping_cart_already_created';

export const ORDER_RESPONSE_NOT_FOUND = 'not_found';
export const ORDER_RESPONSE_SHOPPING_CART_EMPTY = 'shopping_cart_empty';
export const ORDER_RESPONSE_SHOPPING_CART_EXPIRED = 'shopping_cart_expired';
export const ORDER_RESPONSE_SHOPPING_CART_CANCELED = 'shopping_cart_canceled';
export const ORDER_RESPONSE_ORDER_ALREADY_CREATED = 'order_for_this_shopping_cart_already_created';
export const ORDER_RESPONSE_PROVIDER_NOT_RESPONDING = 'payment_service_provider_not_responding';
export const ORDER_RESPONSE_BUYER_INFO_REQUIRED = 'buyer_info_required';
