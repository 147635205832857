import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UniversalDeviceDetectorService } from './universal-device-detector.service';

declare let gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GaService {
  gtagQueue: Function[] = [];
  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object,
    private deviceService: UniversalDeviceDetectorService
  ) {}

  public initialize() {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    this.onRouteChange();

    // dynamically add analytics scripts to document head
    try {
      const url = 'https://www.googletagmanager.com/gtag/js?id=';
      const gTagScript = document.createElement('script');
      gTagScript.async = true;
      gTagScript.src = `${url}${environment.googleAnalyticsId}`;
      document.head.appendChild(gTagScript);

      const dataLayerScript = document.createElement('script');
      dataLayerScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${environment.googleAnalyticsId}', {'send_page_view': false});`;
      document.head.appendChild(dataLayerScript);
    } catch (e) {
      console.error('Error adding Google Analytics', e);
    }
    this.callQueuedEvents();
  }

  private callQueuedEvents() {
    this.gtagQueue.forEach((gtagFunction) => {
      gtagFunction();
    });
    this.gtagQueue = [];
  }
  // track visited routes
  private onRouteChange() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (isPlatformServer(this.platformId) || !environment.production) {
          return;
        }
        if (typeof gtag === 'undefined') {
          this.gtagQueue.push(
            this.callGtag.bind('config', environment.googleAnalyticsId, {
              page_path: event.urlAfterRedirects,
            })
          );
        } else {
          gtag('config', environment.googleAnalyticsId, {
            page_path: event.urlAfterRedirects,
          });
        }
      }
    });
  }

  // use gtag.js to send Google Analytics Events
  public event(eventParams: EventParams) {
    const { action, ...rest } = eventParams;
    if (isPlatformServer(this.platformId) || !environment.production) {
      return;
    }
    if (typeof gtag === 'undefined') {
      this.gtagQueue.push(this.callGtag.bind('event', action, rest));
    } else {
      gtag('event', action, rest);
    }
  }

  private callGtag(...args) {
    /* wrapper to call bind, since gtag might not be loaded yet due to user agreement */
    gtag(this, ...args);
  }

  /*   public eventWithDeviceInfo(action: string){
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.deviceService.getDeviceInfo()
    this.event(action,)
  } */
}

export interface EventParams {
  action: string;
  eventCategory?: string;
  eventLabel?: string;
  value?: string;
  nonInteraction?: boolean;
}
